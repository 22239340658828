/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import PageHead from '../subComponents/PageHead';
import Question from '../subComponents/Question';

const styles = StyleSheet.create({
  anstext: {
    marginLeft: 12,
    fontSize: 10,
    textAlign: 'justify',
  },
});
export default function NormalPage({
  subItemIndex,
  item,
  pascalText,
  subItem,
}) {
  return (
    <>
      {console.log(subItem?.wizards)}
      <PageHead
        subItemIndex={subItemIndex}
        item={item}
        pascalText={pascalText}
        subItem={subItem}
      />
      {subItem?.wizards?.map((wizardItem, index) => (
        <>
          {wizardItem?.questions?.map((questionItem) => (
            <View style={{ marginTop: 12 }}>
              <Question questionItem={questionItem} />
              {questionItem?.subQuestions?.map((subqstn, indx) => (
                <View style={{ marginTop: indx === 0 ? 0 : 8 }}>
                  {subqstn?.title && (
                  <Text
                    style={{ ...styles.anstext, fontStyle: 'italic' }}
                  >
                    {`${subqstn?.title}`}
                  </Text>
                  )}
                  {subqstn?.answers.map((answerItem, ind) => (
                    <>
                      <>
                        <Text
                          style={{ ...styles.anstext, marginTop: 4 }}
                        >
                          {`${answerItem?.answer}`}
                        </Text>
                      </>
                    </>
                  ))}
                </View>
              ))}
            </View>
          ))}
        </>
      ))}
    </>
  );
}
