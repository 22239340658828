import React from 'react';
import {
  Text, StyleSheet, View,
} from '@react-pdf/renderer';
import Title from '../Title';
import FooterCover from '../../../common/Modal/Components/FooterCover';

export default function CoverPage({
  pascalText, user, today, options,
}) {
  const styles = StyleSheet.create({
    cover: {
      paddingTop: 50,
      paddingBottom: 25,
      paddingHorizontal: 35,
    },
    wrap: {
      marginTop: 150,
    },
    title: {
      fontSize: 12,
      textAlign: 'center',
      fontWeight: 500,
    },
    titlesub: {
      fontSize: 12,
      fontWeight: 500,
      textAlign: 'center',
      marginTop: 6,
    },
    container: {
      border: '1 solid #000',
      padding: 14,
      height: '88vh',
    },
  });
  return (
    <>
      {' '}
      <View style={styles.container}>
        <Text style={styles.cover}>
          <Title />
        </Text>
        <View style={styles.wrap}>
          <Text style={styles.title}>
            {`${pascalText(
              user?.middleName
                ? `${user?.firstName}  ${user?.middleName} ${user?.lastName}`
                : `${user?.firstName} ${user?.lastName}`,
            )}'s Information Binder`}
          </Text>
        </View>
        <Text style={styles.titlesub}>
          {' '}
          As of
          {' '}
          {today.toLocaleDateString('en-US', options)}
        </Text>
      </View>
      <FooterCover />

    </>
  );
}
