import React from 'react';
import {
  Text, StyleSheet, View,
} from '@react-pdf/renderer';

export default function Question({ questionItem }) {
  const styles = StyleSheet.create({
    questiontext: {
      margin: 12,
      marginBottom: 10,
      fontSize: 10,
      textAlign: 'justify',
      fontStyle: 'italic',
    },
  });
  return (
    <View>
      <Text style={styles.questiontext}>
        {`${questionItem?.question}`}
      </Text>

    </View>
  );
}
