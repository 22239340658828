/* eslint-disable comma-dangle  */
const endpoints = {
  auth: {
    login: '/auth/login',
    validateLogin: '/auth/validate-login',
    register: '/auth/register',
    verification: '/auth/register-validate',
    forgotPassword: '/auth/forgot-password',
    forgotPasswordVerification: '/auth/password-otp-verify',
    resetPassword: '/auth/reset-password',
    resendEmailOTP: '/auth/resend-otp',
    resendEmailForgotOTP: '/auth/resend-otp-reset-password',
    recoverAccount: '/auth/recover-account',
    recoverAccountOTP: '/auth/validate-recover-otp'
  },
  audit: {
    list: 'audit-trail',
    individallist: '/binder/audit-trail',
    individal: '/audit-trail/item'
  },
  keyContacts: {
    getCategories: '/key-contact/categories',
    keyContact: '/key-contact',
    contactList: '/key-contact/list',
    upload: '/key-contact/upload',
    profileImage: '/key-contact/upload-profile-image',
    permissions: '/key-contact/permission',
    testatorcontactList: '/key-contact/periodic-list'
  },
  dashboard: {
    getSubmenuList: '/binder/categories',
    getCategory: '/binder/categories/topics/',
    addCategory: '/binder/categories/topics/addUsersItem',
    removeCategory: '/binder/categories/topics/removeUsersItem',
    getTopicWizard: '/binder/topicsWizardQuestions/',
    wizardAnswer: '/binder/topicsWizardQuestionsAnswers',
    upload: '/binder/topicsWizardQuestionsAnswerFile',
    getTrust: '',
    quickwill: '/binder/quickWill',
    categoryStatus: 'binder/topic/updateTaskProgress',
    categoryInfo: '/binder/topic/completedTaskPercentage',
    addCustomCategory: '/binder/topic/createCustomTopic',
    editCustomTopic: '/binder/topic/editCustomTopic',
    removeCustomCategory: '/binder/topic/removeCustomTopic'
  },
  search: {
    search: '/search'
  },
  organization: {
    getTrust: '/organization'
  },
  s3: {
    signedUrl: '/upload/getFileUploadPresignedURL'
  },
  subscriptions: {
    getPlans: '/subscription/list',
    setPlan: '/subscription/subscribe',
    viewPlan: '/subscription/subscription',
    validateCoupon: '/subscription/validate-coupon',
    renewPlan: '/subscription/renewSubscribe',
    deletePlan: '/subscription/cancel'
  },
  binder: {
    print: '/binder/print',
    audit: '/audit-trial/item'
  },
  user: {
    getUsersList: '/users',
    getUserInfo: '/users/getUserProfile',
    editProfile: '/users/edit-profile',
    imageUpdate: '/users/uploadUserProfileImage',
    changePassword: '/users/changePassword',
    verifyEmail: '/users/addRecoveryEmail',
    addPhoneNumber: '/users/addPhoneNumber',
    addPhoneValidate: '/users/addPhoneValidate',
    verifyEmailOTP: '/users/addRecoveryEmailValidate',
    verifyPhone: '/users/addRecoverPhone',
    verifyPhoneOTP: '/users/addRecoveryPhoneValidate',
    delete: '/users/delete',
  },
  permissions: {
    permission: '/permission',
    invite: '/permission/invite',
    inviteResponse: '/permission/invite-response',
    roles: '/permission/roles',
    roleDetails: '/permission/role-details',
    reportDeceased: '/permission/report-deceased',
    lastLogin: 'permission/last-login-time',
    QuickPermission: '/permission/quickAdd'
  },
  trustBenficary: {
    trustBenficary: '/organization/beneficiaryTrusty'
  },
  insurance: {
    insurance: '/insurance'
  },
  organizations: {
    organization: '/organization',
    uploadFiles: '/organization/uploadFiles'
  },
  periodic: {
    periodic: '/notification/createPeriodicKeyContactSetting',
    list: '/notification/listReminderKeyContact',
    keycontactUpdate: '/notification/updateReminderKeyContact',
    delete: '/notification/deleteReminderKeyContact',
    Testatorperiodic: '/notification/createPeriodicUserSetting',
    Testatorlist: '/notification/viewPeriodicUserSetting',
    Testatordelete: '/notification/deleteReminderUser',
    Update: '/notification/updatePeriodicUserSetting'
  },
  video: {
    videoHelp: 'https://whitney-help-resources.s3.amazonaws.com/Whitney+-+Sign+in+.mp4'
  },
  urls: {
    americanBarLivingWill:
      'https://www.americanbar.org/groups/real_property_trust_estate/resources/estate_planning/living_wills_health_care_proxies_advance_health_care_directives/',
    americanBarPoa:
      'https://www.americanbar.org/groups/real_property_trust_estate/resources/estate_planning/power_of_attorney/',
    americanBar:
      'https://www.americanbar.org/groups/real_property_trust_estate/resources/estate_planning/'
  }
};

export default endpoints;
