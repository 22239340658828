import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import FooterStyle from '../styles/Footer';

export default function FooterCover() {
  return (

    <View style={FooterStyle.Coverfooter} fixed>
      <Text style={FooterStyle.confidentialText}>Confidential</Text>
    </View>
  );
}
