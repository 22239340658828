/* eslint-disable comma-dangle */
import endpoints from '../endpoints';
import client from '../config/authClient';

const getkeycontactTestatorId = (id) => {
  if (id && id !== '') { return `?testatorId=${id}`; }
  return '';
};
const User = {
  getUsersList: () => client.get(endpoints.user.getUsersList),
  getUserInfo: (testatorId) => client.get(`${endpoints.user.getUserInfo}${getkeycontactTestatorId(testatorId)}`),
  editProfile: (body) => client.put(endpoints.user.editProfile, body),
  updateProfileImage: (body) => client.post(endpoints.user.imageUpdate, body),
  changePassword: (body) => client.post(endpoints.user.changePassword, body),
  verifyEmail: (body) => client.post(endpoints.user.verifyEmail, body),
  verifyEmailOTP: (body) => client.post(endpoints.user.verifyEmailOTP, body),
  addPhoneNumber: (body) => client.post(endpoints.user.addPhoneNumber, body),
  addPhoneValidate: (body) => client.post(endpoints.user.addPhoneValidate, body),

  verifyPhone: (body) => client.post(endpoints.user.verifyPhone, body),
  verifyPhoneOTP: (body) => client.post(endpoints.user.verifyPhoneOTP, body),
};
export default User;
