import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  role: undefined,
  isRole: false,
  isAfterDeathval: 0,
  selectedRole: undefined,
  myroleCount: 0,
  testatorProfile: false,
  testatorManageAccess: false,
  periodicReminderPermission: false,
  IsTrustedAccExp: false,
  decesedStatus: 0,
};

export const roleDataSlice = createSlice({
  name: 'setRole',
  initialState,
  reducers: {
    setRole: (state, action) => {
      state.role = action.payload;
    },
    setIsRole: (state, action) => {
      state.isRole = action.payload;
    },
    setIsAfterDeathRole: (state, action) => {
      state.isAfterDeathval = action.payload;
    },
    setSelectedRole: (state, action) => {
      state.selectedRole = action.payload;
    },
    setMyroleCount: (state, action) => {
      state.myroleCount = action.payload;
    },
    setIsTrustedAccExp: (state, action) => {
      state.IsTrustedAccExp = action.payload;
    },
    setDecesedStatus: (state, action) => {
      state.decesedStatus = action.payload;
    },
    setTestatorProfile: (state, action) => { state.testatorProfile = action.payload; },
    setTestatorManageAccess: (state, action) => { state.testatorManageAccess = action.payload; },
    setPeriodicReminder: (state, action) => { state.periodicReminderPermission = action.payload; },
    clearRole: (state) => {
      state.role = {};
    },
  },
});

export const {
  setRole, clearRole, setIsRole, setIsAfterDeathRole, setSelectedRole, setIsTrustedAccExp,
  setMyroleCount, setTestatorProfile,
  setTestatorManageAccess, setPeriodicReminder, setDecesedStatus,
} = roleDataSlice.actions;

export default roleDataSlice.reducer;
