import React, { Suspense } from 'react';
import {
  BrowserRouter, Routes, Route,
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { RouteList } from './routes/index';
import RequireAuth from './components/RequireAuth/index';
import CheckAuth from './components/RequireAuth/CheckAuth';
import Spinner from './components/common/Spinner';
import './assests/css/styles.css';
import 'react-phone-input-2/lib/style.css';
import './assests/custom/index.css';
import 'react-toastify/dist/ReactToastify.css';
import LeftSideBar from './components/Layout/LeftSideBar';

function App() {
  // console.log = () => null;
  return (
    <div className="main-content">
      <BrowserRouter>
        <ToastContainer />
        <LeftSideBar />
        <Suspense fallback={<Spinner />}>
          <Routes>
            {RouteList.map((route) => (
              <Route
                key={route}
                path={route.path}
                exact
                element={
                route.auth ? (
                  <RequireAuth>
                    <route.element />
                  </RequireAuth>
                ) : (
                  <CheckAuth>
                    <route.element />
                  </CheckAuth>
                )
              }
              />
            ))}
          </Routes>
        </Suspense>
      </BrowserRouter>
    </div>
  );
}

export default App;
