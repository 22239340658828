import React from 'react';
import {

  Text,
  View, Font,
} from '@react-pdf/renderer';
import Styles from '../styles/Style';

export default function Title() {
  Font.register({
    family: 'Roboto',
    fonts: [
      {
        src: 'https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu5GxP.ttf',
      },
      {
        src: 'https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmWUlvAx05IsDqlA.ttf',
        fontWeight: 500,
      },
    ],
  });
  return (
    <View style={Styles.titleHeader}>
      <Text style={Styles.logo}>
        <Text
          style={{
            color: '#4fa5d8',
            fontFamily: 'Roboto',
            fontSize: 20,
            fontWeight: 500,
          }}
        >
          C
        </Text>
        <Text
          style={{
            fontWeight: 500,
            color: '#3b3873',
            fontFamily: 'Roboto',
            fontSize: 20,
          }}
        >
          hron
        </Text>
        <Text
          style={{
            fontWeight: 500,
            color: '#4fa5d8',
            fontFamily: 'Roboto',
            fontSize: 20,
          }}
        >
          W
        </Text>
        <Text
          style={{
            fontWeight: 500,
            color: '#3b3873',
            fontFamily: 'Roboto',
            fontSize: 20,
          }}
        >
          ise
        </Text>
      </Text>
    </View>
  );
}
