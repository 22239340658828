import { StyleSheet } from '@react-pdf/renderer';

const FooterStyle = StyleSheet.create({
  footer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'absolute',
    bottom: 10,
    left: 20,
    right: 20,
  },
  Coverfooter: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    bottom: 10,
    left: 20,
    right: 20,
  },
  confidentialText: {
    fontSize: 12,
    color: 'grey', // Set the color as needed
  },
  pageNumber: {
    fontSize: 12,
    color: 'grey', // Set the color as needed
  },
});
export default FooterStyle;
