/* eslint-disable comma-dangle */
import endpoints from '../endpoints';
import client from '../config/authClient';

const gettestatorId = (id) => {
  if (id && id !== '') {
    return `?userId=${id}`;
  }
  return '';
};
const Subscriptions = {
  getPlans: () => client.get(endpoints.subscriptions.getPlans),
  setPlan: (payload) => client.post(endpoints.subscriptions.setPlan, payload),
  renewPlan: (payload) => client.post(endpoints.subscriptions.renewPlan, payload),
  deletePlan: (id) => client.post(endpoints.subscriptions.deletePlan, id),
  viewPlan: (testatorId) => client.get(
    `${endpoints.subscriptions.viewPlan}${gettestatorId(testatorId)}`
  ),
  validateCoupon: (planId, couponCode) => client.get(
    `${endpoints.subscriptions.validateCoupon}/?planId=${planId}&couponCode=${couponCode}`
  )
};
export default Subscriptions;
