/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-useless-fragment */
import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Badge from 'react-bootstrap/Badge';
import { accExp, sidebar } from '../data';
import Subscriptions from '../../../utils/api/services/subscriptionService';

export default function MenuList({
  getSubList, onClickPermission, hideDrawer, isloading, isPlanExp,
  setIsPlanExp,
}) {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const {
    myroleCount,
  } = useSelector((state) => state.roles) || {};
  const [isPrintable, setIsPrintable] = useState(null);
  const viewPlan = async () => {
    try {
      setLoading(true);
      const response = await Subscriptions.viewPlan();
      if (response?.status === 200) {
        if (response?.data?.length === 0) {
          setIsPlanExp(true);
        } else {
          setIsPlanExp(false);
        }
        setIsPrintable(response.data.isPrintable !== 0);
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };
  useEffect(() => {
    viewPlan();
  }, []);

  return (
    <>
      {loading ? (
        <ul className="list-unstyled listmenus">
          <li className="sidemenu-items">
            <a
              href
              className="pointer d-flex jutify-content-between"
            >
              <span>Loading....</span>
            </a>
          </li>
        </ul>
      ) : (
        <>
          {' '}
          {isPlanExp ? (
            <ul className="list-unstyled listmenus">
              {accExp?.map((acc) => (
                <li className="sidemenu-items">
                  <a
                    href
                    onClick={() => onClickPermission(acc)}
                    className={
            location?.pathname?.includes(acc.url)
              ? 'active pointer'
              : 'pointer'
          }
                  >
                    <img src={acc?.img} alt="home" />
                    {acc?.name === 'My Roles' ? (
                      <>
                        <span>{acc?.name}</span>
                        {myroleCount > 0 && (
                          <Badge
                            className="p-badge ms-4"
                            shape="rounded-circle"
                          >
                            {myroleCount}
                          </Badge>
                        )}
                      </>
                    ) : (
                      <span>{acc?.name}</span>
                    )}
                  </a>
                </li>
              ))}
            </ul>
          )
            : (
              <ul className="list-unstyled listmenus">
                {sidebar?.map((item) => (item?.name === 'My Information Binder' ? (
                  getSubList(item)
                ) : (
                  <>
                    {item?.name === 'My Roles' || item?.name === 'Manage Access' ? (
                      <li className="sidemenu-items">
                        <a
                          href
                          onClick={() => onClickPermission(item)}
                          className={
                  location?.pathname?.includes(item.url)
                    ? 'active pointer'
                    : 'pointer'
                }
                        >
                          <img src={item?.img} alt="home" />
                          {item?.name === 'My Roles' ? (
                            <>
                              <span>{item?.name}</span>
                              {myroleCount > 0 && (
                              <Badge
                                className="p-badge ms-4"
                                shape="rounded-circle"
                              >
                                {myroleCount}
                              </Badge>
                              )}
                            </>
                          ) : (
                            <span>{item?.name}</span>
                          )}
                        </a>
                      </li>
                    ) : (
                      <>
                        {item?.name === 'Help' ? (
                          <li className="sidemenu-items">
                            <a
                              href
                              onClick={() => hideDrawer(item?.name)}
                              className={
                      location?.pathname?.includes(item.url)
                        ? 'active pointer'
                        : 'pointer'
                    }
                            >
                              <img src={item?.img} alt="home" />
                              <span>{item?.name}</span>
                            </a>
                          </li>
                        ) : (
                          <>
                            {item?.name === 'Print Information Binder' ? (
                              <>
                                {isPrintable ? (
                                  <li className="sidemenu-items">
                                    <a
                                      href
                                      onClick={() => hideDrawer(item?.name)}
                                      className={
                        location?.pathname?.includes(item.url)
                          ? (isloading ? 'active pointer disable' : 'active pointer')
                          : isloading ? 'pointer disable' : 'pointer'
                      }
                                    >
                                      <img src={item?.img} alt="home" />
                                      <span>{item?.name}</span>
                                    </a>
                                  </li>
                                ) : null}

                              </>
                            ) : (
                              <li className="sidemenu-items">
                                <Link
                                  onClick={() => hideDrawer()}
                                  to={item.url}
                                  className={
                    location?.pathname?.includes(item.url)
                      ? 'active'
                      : ''
                  }
                                >
                                  <img src={item?.img} alt="home" />
                                  <span>{item?.name}</span>
                                </Link>
                              </li>
                            )}

                          </>

                        )}
                      </>
                    )}
                  </>
                )))}
              </ul>
            )}
        </>
      )}

    </>
  );
}
