/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import PageHead from '../subComponents/PageHead';

const styles = StyleSheet.create({
  anstext: {
    marginLeft: 12,
    fontSize: 10,
    textAlign: 'justify',
  },
  horizontalLine: {
    borderBottomWidth: 1,
    // flex: 1,
    borderBottomColor: '#A9ACAB',
    marginRight: 10,
    marginTop: 8,
  },
  horizontalLineT: {
    borderBottomWidth: 1,
    // flex: 1,
    borderBottomColor: '#A9ACAB',
    marginLeft: 10,
    marginRight: 10,
  },
  typeAnstext: {
    fontSize: 10,
    textAlign: 'justify',
    fontWeight: 500,
    marginTop: 8,
  },
  wrapper: {
    fontSize: 10,
    textAlign: 'justify',
    marginTop: 20,
  },
  Qanstext: {
    marginTop: 4,
  },
  questiontext: {
    margin: 12,
    marginBottom: 0,
    fontSize: 10,
    textAlign: 'justify',
    fontStyle: 'italic',
  },
});
export default function BankInvestment({
  subItemIndex,
  item,
  pascalText,
  subItem,
}) {
  return (
    <>
      <PageHead
        subItemIndex={subItemIndex}
        item={item}
        pascalText={pascalText}
        subItem={subItem}
      />
      {subItem?.wizards?.map((wizardItem, index) => (
        <>
          {wizardItem?.questions?.map((questionItem) => (
            <>
              <Text style={styles.questiontext}>
                {`${questionItem?.question}`}
              </Text>
              {questionItem?.subQuestions?.map((subqstn) => subqstn?.answers?.map((answerItem, ind) => (
                <View style={{ marginTop: 8, marginLeft: 12 }}>
                  <Text style={styles.typeAnstext}>
                    {`${answerItem?.answer}`}
                  </Text>
                  <Text style={styles?.horizontalLine} />
                  {answerItem?.wizards?.map((question) => question?.questions?.map((ques) => (
                    <View style={styles.wrapper}>
                      <Text style={{ fontStyle: 'italic' }}>
                        {ques?.question}
                      </Text>
                      {ques?.subQuestions?.map((sub) => sub?.answers?.map((ans) => (
                        <View style={{ marginTop: 4 }}>
                          <Text
                            style={{
                              ...styles.Qanstext,
                              fontStyle: 'italic',
                            }}
                          >
                            {sub?.title}
                          </Text>
                          {ans?.wizards ? (
                            <>
                              <View style={{ marginLeft: 12 }}>
                                <Text
                                  style={{
                                    marginTop: 4,
                                    fontWeight: 500,
                                  }}
                                >
                                  {ans?.answer}
                                </Text>
                                {ans?.wizards?.map((wiz) => wiz?.questions?.map((qu) => (
                                  <View style={{ marginTop: 12 }}>
                                    <Text style={{ fontStyle: 'italic' }}>{qu?.question}</Text>
                                    {qu?.subQuestions?.map((subQ) => (
                                      <>
                                        {subQ?.answers?.map((suA) => (
                                          <Text
                                            style={{ marginTop: 4 }}
                                          >
                                            {suA?.answer}
                                          </Text>
                                        ))}
                                      </>
                                    ))}
                                  </View>
                                )))}
                              </View>
                            </>
                          ) : (
                            <Text style={styles.Qanstext}>
                              {ans?.answer}
                            </Text>
                          )}
                        </View>
                      )))}
                    </View>
                  )))}
                </View>
              )))}
            </>
          ))}
        </>
      ))}
    </>
  );
}
