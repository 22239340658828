import { toast as toasts } from 'react-toastify';

const Toast = (message, type) => {
  console.log('message', message);
  toasts(message, {
    hideProgressBar: true,
    theme: 'colored',
    autoClose: 2000,
    type,
  });
};
export default Toast;
