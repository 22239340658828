/* eslint-disable no-return-assign */
import axios from 'axios';
import AuthHelper from '../../AuthHelper';
import Toast from '../../../components/common/Toast/Toast';

const API_URL = process.env.REACT_APP_BASE_URL;
let sessionTimeoutToastShown = false;

const authClient = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

authClient.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('AuthToken');
    const testatorId = localStorage.getItem('testatorId');
    if (token) {
      config.headers.testatorId = testatorId;
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error),
);

authClient.interceptors.response.use(
  (response) => response,

  (error) => {
    if (error?.response?.status === 401 && !sessionTimeoutToastShown) {
      AuthHelper.clearAll();
      Toast('Session timeout.Please login again', 'error');
      sessionTimeoutToastShown = true;
      setTimeout(() => window.location.href = (`${window.location.origin}/login`), 1000);
    }
    return Promise.reject(error);
  },
);

export default authClient;
