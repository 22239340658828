/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  keycontact: undefined,
};

export const keycontactDataSlice = createSlice({
  name: 'keycontact',
  initialState,
  reducers: {
    setKeycontact: (state, action) => {
      state.keycontact = action.payload;
    },
  },
});

export const { setKeycontact } = keycontactDataSlice.actions;

export default keycontactDataSlice.reducer;
