/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import ImgArrow from '../../assests/images/arrow-right.svg';
import SidebarMenu from './Sidebar';
import { setOpen } from '../../redux/slices/sideBarDataSlice';
import AuthHelper from '../../utils/AuthHelper';

export default function LeftSideBar() {
  const dispatch = useDispatch();
  const loc = useLocation();
  const location = loc?.pathname;
  const LocationArr = ['/renew-plan', '/plans', '/payment',
    '/payment-success', '/login', '/registration',
    '/forgotPassword',
    '/recover',
    '/recover-verify',
    '/resetPassword',
    '/verifyOtp',
    '/executor-invite',
  ];
  const { open } = useSelector((state) => state.sidebar) || {};
  const { authToken } = useSelector((state) => state.user) || {};
  const checkLocationPath = () => {
    if (LocationArr?.includes(location)) {
      return false;
    }
    return true;
  };
  return (
    <>
      {checkLocationPath() && (authToken || AuthHelper.getAuthToken()) ? (
        <div className={open ? 'leftside-bar open' : 'leftside-bar'}>
          <div className="leftside-collapse d-xl-none d-block shadow-sm" id="filter">
            <button
              type="button"
              className="mob-menu"
              onClick={() => {
                dispatch(setOpen(!open));
              }}
            >
              <div className="mobilemenu">
                <div className="arrow-right">
                  <img src={ImgArrow} alt="arrow" width="20" />
                </div>
              </div>
            </button>
          </div>
          <SidebarMenu />
        </div>
      ) : null}
    </>
  );
}
