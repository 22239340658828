/* eslint-disable no-debugger */
/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import AuthHelper from '../../utils/AuthHelper';

export default function CheckAuth({ children }) {
  if (AuthHelper.validToken() && window.location.pathname !== '/executor-invite') {
    window.location.replace('/dashboard');
    return '';
  }
  return (
    <>{ children }</>
  );
}
