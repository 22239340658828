import { StyleSheet } from '@react-pdf/renderer';

const Styles = StyleSheet.create({
  page: {
    size: 'A4',
    padding: 20,
  },
  container: {},
  titleHeader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 1,
  },
  subtitleContent: {
    marginTop: 150,
    textAlign: 'center',
    fontWeight: 'bold',
  },
  subtitle2Content: {
    textAlign: 'center',
    marginTop: 12,
    fontWeight: 'bold',
  },
  subText2: {
    alignItems: 'center',
    textAlign: 'center',
  },
  border: {
    border: '1 solid #000',
    padding: 25,
  },
  elementPixel: {
    position: 'relative',
  },
  elementPixelBackground: {
    background: 'linear-gradient(-135deg, transparent 20px, #3b3873 0)',
    width: 30,
    height: 20,
    margin: 0,
  },
  elementPixels: {
    position: 'relative',
  },
  elementPixelsBackground: {
    background: 'linear-gradient(-135deg, transparent 20px, lightblue 0)',
    height: 20,
    margin: 0,
  },
  fill1: {
    width: '40%',
    backgroundColor: '#e14427',
  },
  headertitle: {
    display: 'flex',
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  headerText: {
    fontFamily: 'Open Sans',
    color: '#000',
    fontWeight: 'heavy',
    fontSize: 18,
  },
  headerTextsubTitle: {
    fontFamily: 'Open Sans',
    color: '#0f597d',
    fontWeight: 'heavy',
    fontSize: 18,
  },
  headerText2: {
    fontFamily: 'Open Sans',
    color: '#0f597d',
    fontWeight: 'heavy',
    fontSize: 16,
    marginBottom: 8,
  },
  contenttext: {
    fontSize: 12,
    color: '#161616',
    marginLeft: 10,
    marginBottom: 8,
  },
  wizzardText: {
    fontFamily: 'Open Sans',
    color: '#0f597d',
    fontSize: 12,
    fontWeight: 'heavy',
    marginBottom: 8,
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 4,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
  date: {
    position: 'absolute',
    fontSize: 12,
    bottom: 4,
    left: 0,
    right: 8,
    textAlign: 'right',
    color: 'grey',
  },
  containerFooter: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  watermarkOverlay: {
    position: 'absolute',
    bottom: 350,
    left: 100,
    width: '100%',
    height: '100%',
    pointerEvents: 'none',
  },
  watermark: {
    position: 'absolute',
    bottom: 50,
    left: 30,
    transform: 'rotate(-45deg)',
    color: 'rgba(0, 0, 0, 0.2)',
    fontSize: 60,
    zIndex: 1,
  },
  nonBoldTextanswer: {
    marginLeft: 40, // Adjust as needed to control indentation
    fontSize: 12, // Adjust the font size
    color: 'black', // Set the text color
    fontFamily: 'Open Sans',
    fontWeight: 'heavy',
    marginTop: 4,
    marginBottom: 8,
  },
  boldTextanswer: {
    marginLeft: 80, // Adjust as needed to control indentation
    fontSize: 12, // Adjust the font size
    color: 'black',
    marginBottom: 8, // Set the text color
  },
  logo: {
    textAlign: 'center',
  },
  answertextwrap: {
    display: 'flex',
  },
  answertext: {
    marginLeft: 45,
    marginBottom: 8,
  },
  answertexts: {
    marginLeft: 45,
    marginBottom: 8,
    display: 'block',
  },
  indexHeading: {
    fontSize: 25,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  tableMain: {
    // padding: 20,
    color: '#1b4761',
  },
  tableSubBullets: {
    padding: 10,
    color: 'black',
  },
  tableBullets: {
    padding: 10,
  },
  liMb: {
    marginBottom: 5,
  },
  Grid: {
    display: 'flex',
    // justifyContent: 'space-between',
    flexDirection: 'row',
  },
  horizontalLine: {
    borderBottom: 1,
    borderColor: 'black',
    marginTop: 5, // Adjust the spacing as needed
  },
});

export default Styles;
