import React from 'react';
import { Text, StyleSheet, View } from '@react-pdf/renderer';
import useUtils from '../../../utils';

export default function PersonalData({ userInfo }) {
  const { formatUsPhoneNumber } = useUtils();
  const Styles = StyleSheet.create({
    // ....................
    title: {
      fontSize: 14,
      color: '#0f597d',
      fontFamily: 'Roboto',
      marginBottom: 10,
      fontWeight: 500,
    },
    author: {
      fontSize: 10,
      fontFamily: 'Roboto',
      marginBottom: 4,
      marginTop: 2,
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      marginBottom: 4, // Adjust spacing between rows
    },
    column: {
      flex: 1, // Each column takes equal space
      marginRight: 8, // Adjust spacing between columns
    },
  });

  function capitalizeFirstLetter(sentence) {
    return sentence.charAt(0).toUpperCase() + sentence.slice(1);
  }

  const getAddress = () => {
    const address = [
      userInfo?.addressLine1,
      userInfo?.addressLine2,
      userInfo?.city,
      userInfo?.state,
      userInfo?.country,
      userInfo?.zipcode,
    ];
    return address.filter(Boolean).join(', ') || '-';
  };

  return (
    <View style={{ padding: 12 }}>
      <Text style={Styles.title}>Personal Information</Text>
      <View style={Styles.wrapContent}>
        <View style={Styles.row}>
          <View style={Styles.column}>
            <Text style={Styles.author}>First name:</Text>
          </View>
          <View style={Styles.column}>
            <Text style={Styles.author}>{userInfo?.firstName || '-'}</Text>
          </View>
        </View>
        <View style={Styles.row}>
          <View style={Styles.column}>
            <Text style={Styles.author}>Middle name:</Text>
          </View>
          <View style={Styles.column}>
            <Text style={Styles.author}>{userInfo?.middleName || '-'}</Text>
          </View>
        </View>
        <View style={Styles.row}>
          <View style={Styles.column}>
            <Text style={Styles.author}>Last name:</Text>
          </View>
          <View style={Styles.column}>
            <Text style={Styles.author}>{userInfo?.lastName || '-'}</Text>
          </View>
        </View>
        <View style={Styles.row}>
          <View style={Styles.column}>
            <Text style={Styles.author}>Suffix:</Text>
          </View>
          <View style={Styles.column}>
            <Text style={Styles.author}>{userInfo?.suffix || '-'}</Text>
          </View>
        </View>
        <View style={Styles.row}>
          <View style={Styles.column}>
            <Text style={Styles.author}>Nickname:</Text>
          </View>
          <View style={Styles.column}>
            <Text style={Styles.author}>{userInfo?.nickName || '-'}</Text>
          </View>
        </View>
        <View style={Styles.row}>
          <View style={Styles.column}>
            <Text style={Styles.author}>Gender:</Text>
          </View>
          <View style={Styles.column}>
            <Text style={Styles.author}>
              {userInfo?.gender ? capitalizeFirstLetter(userInfo?.gender) : '-'}
            </Text>
          </View>
        </View>
        <View style={Styles.row}>
          <View style={Styles.column}>
            <Text style={Styles.author}>Address:</Text>
          </View>
          <View style={Styles.column}>
            <Text style={Styles.author}>{getAddress()}</Text>
            {userInfo?.country && (
            <Text style={Styles.author}>
              Country:
              {userInfo?.country || '-'}
            </Text>
            )}
          </View>
        </View>
        <View style={Styles.row}>
          <View style={Styles.column}>
            <Text style={Styles.author}>Phone number:</Text>
          </View>
          <View style={Styles.column}>
            <Text style={Styles.author}>
              {userInfo?.phoneNumber && userInfo?.phCountryCode
                ? `+${userInfo?.phCountryCode}${' '}${formatUsPhoneNumber(
                  userInfo?.phCountryCode,
                  userInfo?.phoneNumber,
                )}`
                : '-'}
            </Text>
          </View>
        </View>
      </View>
    </View>
  );
}
