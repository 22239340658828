import React from 'react';
import {
  Text, StyleSheet,
} from '@react-pdf/renderer';

export default function PageHead({
  subItemIndex, item, pascalText, subItem,
}) {
  const styles = StyleSheet.create({
    title: {
      fontSize: 12,
      textAlign: 'center',
      fontWeight: 500,
    },
    author: {
      fontSize: 12,
      textAlign: 'center',
      marginBottom: 12,
      marginTop: 16,
    },
  });
  return (
    <>
      {subItemIndex === 0 && (
      <Text style={styles.title}>
        Category:
        {' '}
        {pascalText(item?.title)}
      </Text>
      )}
      <Text style={styles.author}>
        Topic:
        {' '}
        {subItem?.title}
      </Text>

    </>
  );
}
