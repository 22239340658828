/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-unreachable */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import {
  Document,
  Font,
  Page,
  StyleSheet,
  Text,
  PDFViewer,
  View, BlobProvider,
} from '@react-pdf/renderer';
import { useSelector } from 'react-redux';
import Title from './Title';
import useUtils from '../../../utils';
import AuthHelper from '../../../utils/AuthHelper';
import PersonalData from './PersonalData';
import TableContent from './TableContent';
import GetMultipleData from './GetMultipleData';
import Footer from './Footer';
import New from './UpdatedBinder';
import MainData from './MainData';

function Quixote() {
  const user = AuthHelper.getUserInfo();
  const { pascalText } = useUtils();
  const { printKeyContact, binderData } = useSelector((state) => state.infoBinder);
  const today = new Date();
  const tmpTableOfContents = [];
  const tmpTableOfSubContents = [];
  const tmpTableOfPersonal = [];
  const [isTableOfContentsRendered, setIsTableOfContentsRendered] = useState(false);
  const [tableOfContents, setTableOfContents] = useState([]);
  const [tableOfSubContents, setTableOfSubContents] = useState([]);

  const isCheck = (data) => {
    if (data?.length === 1) {
      return true;
    }
    return false;
  };
  return (
    <div className="contents">
      {isTableOfContentsRendered
      && (
      <div className="m-4 text-end" style={{ cursor: 'pointer' }}>
        <BlobProvider
          document={(
            <MainData
              isCheck={isCheck}
              binderData={binderData}
              printKeyContact={printKeyContact}
              pascalText={pascalText}
              user={user}
              today={today}
              tableOfContents={tableOfContents}
              tableOfSubContents={tableOfSubContents}
            />
          )}
        >
          {({
            blob, url, loading, error,
          }) => {
            if (loading) {
              return <p />;
            }
            if (error) {
              return <p />;
            }
            return (
              <div>
                <a
                  href={url}
                  target="_blank"
                  rel="noopener noreferrer"
                  download="Infobinder.pdf"
                >
                  <svg
                    id="Layer_1"
                    data-name="Layer 1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 48 48"
                    fill="#023047"
                    width="30px"
                  >
                    <path d="M37,12V6a2,2,0,0,0-2-2H13a2,2,0,0,0-2,2v6Z" />
                    <path d="M11,28V42a2,2,0,0,0,2,2H35a2,2,0,0,0,2-2V28ZM32.5,41h-17a.5.5,0,0,1,0-1h17a.5.5,0,0,1,0,1Zm0-3h-17a.5.5,0,0,1,0-1h17a.5.5,0,0,1,0,1Zm0-3h-17a.5.5,0,0,1,0-1h17a.5.5,0,0,1,0,1Zm0-3h-17a.5.5,0,0,1,0-1h17a.5.5,0,0,1,0,1Z" />
                    <path d="M41,14H7a3,3,0,0,0-3,3V34a3,3,0,0,0,3,3H9V28a2,2,0,0,1,2-2H37a2,2,0,0,1,2,2v9h2a3,3,0,0,0,3-3V17A3,3,0,0,0,41,14Zm-5,6H29.5a.5.5,0,0,1,0-1H36a.5.5,0,0,1,0,1Zm3.25.25A.75.75,0,1,1,40,19.5.75.75,0,0,1,39.25,20.25Z" />
                  </svg>
                  Download as PDF
                </a>
              </div>
            );
          }}
        </BlobProvider>
      </div>
      )}
      <div className="d-flex justify-content-center">
        {!isTableOfContentsRendered ? (
          <New
            setTableOfSubContents={setTableOfSubContents}
            binderData={binderData}
            printKeyContact={printKeyContact}
            today={today}
            pascalText={pascalText}
            user={user}
            tmpTableOfContents={tmpTableOfContents}
            tmpTableOfSubContents={tmpTableOfSubContents}
            isCheck={isCheck}
            setIsTableOfContentsRendered={setIsTableOfContentsRendered}
            setTableOfContents={setTableOfContents}
            tmpTableOfPersonal={tmpTableOfPersonal}
          />
        ) : (
          <>
            <PDFViewer
              width={700}
              style={{ height: 'calc(100vh - 152px)' }}
              showToolbar={false}
            >
              <MainData
                isCheck={isCheck}
                binderData={binderData}
                printKeyContact={printKeyContact}
                pascalText={pascalText}
                user={user}
                today={today}
                tableOfContents={tableOfContents}
                tableOfSubContents={tableOfSubContents}
              />
            </PDFViewer>

          </>
        )}
      </div>

    </div>
  );
}

export default Quixote;
