/* eslint-disable no-irregular-whitespace */
/* eslint-disable max-len  */
import { Country, State, City } from 'country-state-city';

const useUtils = () => {
  const getCountry = Country.getAllCountries();
  const zipRegex = /^\d{5}(?:[-\s]\d{4})?$/;

  const getList = (list) => list.map((item) => ({
    value: item.isoCode,
    label: item.name,
  }));
  const getLabel = (label) => `Done with ${label?.toLowerCase()}`;
  const pascalText = (string) => string
    && string.replace(
      /(\w)(\w*)/g,
      (g0, g1, g2) => g1.toUpperCase() + g2.toLowerCase(),
    );

  const getOptions = (valueField, labelField, codeField, list) => list.map((item) => ({
    value: item[valueField],
    label: item[labelField],
    code: item[codeField],
  }));

  const getCountryOptions = () => {
    const countries = getList(Country.getAllCountries());
    return countries;
  };

  const getCountryIdFromLabel = (label) => {
    const countries = getList(Country.getAllCountries());
    return countries.find((item) => item?.label === label)?.value;
  };

  const getStateOptions = (country) => {
    const state = getList(State.getStatesOfCountry(country));
    return state;
  };

  const getStateIdFromLabel = (state, country) => {
    const states = getList(State.getStatesOfCountry(country));
    return states.find((item) => item.label === state)?.value;
  };

  const getCityOptions = (country, state) => {
    const cities = getOptions(
      'name',
      'name',
      City.getCitiesOfState(country, state),
    );
    return cities;
  };

  const necessity = (ids) => {
    let result = null;
    if (ids === 0) {
      result = 'Optional';
    } else if (ids === 1) {
      result = 'Recommended';
    } else if (ids === 2) {
      result = 'Important';
    }
    return result;
  };
  const yesnoLabel = (label) => {
    let result = 'No';
    if (
      label === 'Beneficiaries'
      || label === 'Executors'
      || label === 'Trustees'
    ) {
      result = 'No, I’ll add them later';
    }
    return result;
  };
  const findOption = (id, field, list) => list.find((item) => id === item[field]);

  const flatten = (a) => (Array.isArray(a) ? [].concat(...a.map(flatten)) : a);

  const formatBytes = (bytes, decimals = 2) => {
    if (!+bytes) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
  };
  const getModalDescription = (title, category) => {
    if (title === 'Power of attorney') {
      return 'Please select a power of attorney OR create one.';
    }
    if (title === 'Healthcare proxy') {
      return 'Please select a Healthcare proxy OR create one.';
    }
    if (title === 'Trusts') {
      return 'Please select a trust OR create one.';
    }
    if (category === 'organization') {
      return `Please select a ${title.toLowerCase()} OR create one.`;
    }
    if (category === 'insurance') {
      return `Please select a ${title.toLowerCase()} OR create one.`;
    }
    if (category === 'forTheHome') {
      return `Please select a ${title.toLowerCase()} OR create one.`;
    }
    if (category === 'keyContactList') {
      return `Please select a ${title.toLowerCase()} OR create one.`;
    }
    return null;
  };
  const wizardUpdate = (data, wizardList, wizard) => {
    const index = wizardList?.findIndex((item) => item?.btwTitle === wizard);
    const newWizardList = wizardList?.filter(
      (item) => item?.btwTitle !== wizard,
    );
    newWizardList.splice(index, 0, data);
    const datas = wizardList.map((e) => {
      const temp = data.find((element) => element.btwId === e.btwId);
      if (temp) {
        e = temp;
      }
      return e;
    });
    return datas;
  };

  const findItemById = (id, list) => list.find((item) => item.id === id);
  const findItemByIdField = (id, idField, list) => list.find((item) => item[idField] === id);

  const replaceItem = (itemId, newItem, list) => list.map((x) => (x.id === itemId ? newItem : x));

  const getGreeting = () => {
    const today = new Date();
    const curHr = today.getHours();
    if (curHr < 12) {
      return 'Good morning';
    }
    if (curHr < 18) {
      return 'Good afternoon';
    }
    return 'Good evening';
  };

  const trimFileName = (file, length) => {
    const name = file.split('.')[0];
    const lastFive = name.substr(name.length - 5);
    const fileExt = file.substring(file.lastIndexOf('.') + 1);
    if (file.length > length) {
      file = `${file.substring(0, length)}...${lastFive}.${fileExt}`;
    }
    return file;
  };
  const getMessage = (name) => `Dear ${name}, 

I would like to add you as a Trusted Person in ChronWise. As a Trusted Person, you will be able to view confidential information that I share with you. 

Please click on the link below to create an account on ChronWise. You need to have an account so I can share my information with you securely. You will not have to pay to view my information.

I will call to follow up with you as well.

Regards`;
  const formatUsPhoneNumber = (country, phone) => {
    if (country === '1') {
      return `(${phone.substring(0, 3)}) ${phone.substring(
        3,
        6,
      )}-${phone.substring(6, 11)}`;
    }
    return phone;
  };
  const getDate = (dates) => {
    const isoDateString = dates;
    const date = new Date(isoDateString);

    const year = date.getFullYear();
    const monthName = date.toLocaleString('default', { month: 'long' });
    const day = date.getDate().toString().padStart(2, '0');
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');

    const formattedDateString = `${monthName} ${day}, ${year} ${hours}:${minutes}:${seconds}`;
    return formattedDateString;
  };

  const validationArr = {
    DATE: 'date',
    FOURDIGITNUMBER: 'fourDigitNumber',
    NUMBER: 'number',
    CHARACTER: 'character',
  };
  const getErroMessage = (type) => {
    switch (type) {
      case 'fourDigitNumber':

        return 'Please enter four digit number.';
      // Add more validation cases as needed
      default:
        return type; // Default to true if no validation is needed
    }
  };
  const getPrintMessage = (type) => {
    switch (type) {
      case 0:
        return 'Your Information Binder is empty.';
      case 1:
        return 'Data is ready! You can now proceed to print your Information Binder.';
      case 2:
        return 'Failed to fetch data.';
      // Add more validation cases as needed
      default:
        return 'Failed to fetch data.'; // Default to true if no validation is needed
    }
  };
  const statusList = [
    'In progress',
    'Finished'];
  return {
    getCountry,
    zipRegex,
    validationArr,
    statusList,
    getPrintMessage,
    trimFileName,
    formatUsPhoneNumber,
    getCountryOptions,
    getStateOptions,
    getCityOptions,
    flatten,
    getOptions,
    necessity,
    findOption,
    getCountryIdFromLabel,
    yesnoLabel,
    getStateIdFromLabel,
    formatBytes,
    getModalDescription,
    wizardUpdate,
    pascalText,
    findItemById,
    replaceItem,
    findItemByIdField,
    getGreeting,
    getMessage,
    getDate,
    getErroMessage,
    getLabel,
  };
};

export default useUtils;
