/* eslint-disable comma-dangle */
import { lazy } from 'react';

const routes = [
  {
    path: '/',
    element: lazy(() => import('../pages/registration')),
    auth: false
  },
  {
    path: '/login',
    element: lazy(() => import('../pages/login')),
    auth: false
  },
  {
    path: '/registration',
    element: lazy(() => import('../pages/registration')),
    auth: false
  },
  {
    path: '/forgotPassword',
    element: lazy(() => import('../pages/forgotPassword')),
    auth: false
  },
  {
    path: '/recover',
    element: lazy(() => import('../pages/recoverEmailPhone')),
    auth: false
  },
  {
    path: '/recover-verify',
    element: lazy(() => import('../pages/recoverEmailPhone/recoverOtp')),
    auth: false
  },
  {
    path: '/resetPassword',
    element: lazy(() => import('../pages/resetPassword')),
    auth: false
  },
  {
    path: '/verifyOtp',
    element: lazy(() => import('../pages/otp')),
    auth: false
  },
  {
    path: '/executor-invite/*',
    element: lazy(() => import('../pages/executor/Invitation')),
    auth: false
  },
];
export default routes;
