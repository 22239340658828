/* eslint-disable no-debugger */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Document,
  Font,
  Page,
  StyleSheet,
  Text,
  PDFViewer,
  View, BlobProvider, PDFDownloadLink,
} from '@react-pdf/renderer';
import { setPrintSuccessMsg } from '../../../redux/slices/infoBinderSlice';
import useutils from '../../../utils/index';
import MyDocument from './Mydoc';
import BinderInfo from '../../infoSummary/Components/BinderInfo';
import AuthHelper from '../../../utils/AuthHelper';
import UpdatedBinder from '../../infoSummary/Components/UpdatedBinder';
import MainData from '../../infoSummary/Components/MainData';
import User from '../../../utils/api/services/userService';

export default function PrintSuccessNotif({ printSuccessMsg }) {
  const dispatch = useDispatch();
  const { getPrintMessage, pascalText } = useutils();
  const navigate = useNavigate();
  const {
    binderData, printKeyContact, InfoLoad, userData,
  } = useSelector((state) => state.infoBinder);
  const user = AuthHelper.getUserInfo();
  const today = new Date();
  const tmpTableOfContents = [];
  const tmpTableOfSubContents = [];
  const tmpTableOfPersonal = [];
  const [isTableOfContentsRendered, setIsTableOfContentsRendered] = useState(false);
  const [tableOfContents, setTableOfContents] = useState([]);
  const [tableOfSubContents, setTableOfSubContents] = useState([]);
  const isCheck = (data) => {
    if (data?.length === 1) {
      return true;
    }
    return false;
  };

  const getloading = (loading, isTableOfContentsRendereds, InfoLoads) => {
    if (binderData?.length > 0) {
      if (loading || !isTableOfContentsRendereds || InfoLoads) {
        return true;
      }
      return false;
    }
    if (loading || InfoLoads) {
      return true;
    }
    return false;
  };
  return (

    <Modal
      show
      backdrop="static"
      keyboard={false}
      size="lg"
      className="pb-3"
      aria-labelledby="contained-modal-title-vcenter"
      top
      onHide={() => dispatch(setPrintSuccessMsg({ istrue: false, mssg: '' }))}
    >
      <Modal.Header className="p-4" closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          <h3 className="text-prmary fw-bold my-0 d-flex align-items-center">
            Information Binder
          </h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>

        <>
          {' '}
          <p>
            <h6>
              {getPrintMessage(printSuccessMsg?.mssg)}
              {' '}
            </h6>
          </p>
          <div className="d-flex justify-content-center d-none">
            {!isTableOfContentsRendered ? (
              <UpdatedBinder
                setTableOfSubContents={setTableOfSubContents}
                binderData={binderData}
                printKeyContact={printKeyContact}
                today={today}
                pascalText={pascalText}
                user={user}
                tmpTableOfContents={tmpTableOfContents}
                tmpTableOfSubContents={tmpTableOfSubContents}
                isCheck={isCheck}
                setIsTableOfContentsRendered={setIsTableOfContentsRendered}
                setTableOfContents={setTableOfContents}
                tmpTableOfPersonal={tmpTableOfPersonal}
                userInfo={userData}
              />
            ) : (
              <PDFViewer
                width={700}
                style={{ height: 'calc(100vh - 152px)' }}
                showToolbar={false}
              >
                <MainData
                  isCheck={isCheck}
                  binderData={binderData}
                  printKeyContact={printKeyContact}
                  pascalText={pascalText}
                  user={user}
                  today={today}
                  tableOfContents={tableOfContents}
                  tableOfSubContents={tableOfSubContents}
                  userInfo={userData}
                />
              </PDFViewer>
            )}
          </div>
        </>

      </Modal.Body>
      <Modal.Footer>
        <Button
          className="btn btn-danger"
          onClick={() => {
            dispatch(setPrintSuccessMsg({ istrue: false, mssg: '' }));
          }}
        >
          {printSuccessMsg?.mssg === 0 ? 'Okay' : 'Cancel' }
        </Button>
        {printSuccessMsg?.mssg === 1
        && (
        <PDFDownloadLink
          document={(
            <MainData
              isCheck={isCheck}
              binderData={binderData}
              printKeyContact={printKeyContact}
              pascalText={pascalText}
              user={user}
              today={today}
              tableOfContents={tableOfContents}
              tableOfSubContents={tableOfSubContents}
              userInfo={userData}
            />
)}
          fileName={`${pascalText(
            user?.middleName
              ? `${user?.firstName}  ${user?.middleName} ${user?.lastName}`
              : `${user?.firstName} ${user?.lastName}`,
          )} Chronwise Information Binder`}
        >
          {({
            blob, url, loading, error,
          }) => (getloading(loading, isTableOfContentsRendered, InfoLoad)
            ? (
              <Button
                className="btn btn-primary"
                disabled={getloading(loading, isTableOfContentsRendered, InfoLoad)}
                onClick={() => {
                  // dispatch(setPrintSuccessMsg({ istrue: false, mssg: '' }));
                  // navigate('/infobinder/print');
                  // handleDownloadAndView();
                }}
              >
                Loading document...
              </Button>
            )
            : (
              <Button
                disabled={getloading(loading, isTableOfContentsRendered, InfoLoad)}
                className="btn btn-primary"
              >
                Download PDF
              </Button>
            ))}

        </PDFDownloadLink>
        )}
      </Modal.Footer>
    </Modal>

  );
}
