/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import {
  View, Text, Font, StyleSheet,
} from '@react-pdf/renderer';
import Footer from './Footer';
// import Styles from '../styles/Style';

export default function TableContent({
  tableOfPersonalinfo, tableOfSubContents, tmpTableOfContents, binderData,
}) {
  const Styles = StyleSheet.create({
    // ....................
    title: {
      fontSize: 14,
      color: '#0f597d',
      // textAlign: 'center',
      fontFamily: 'Roboto',
      marginBottom: 10,
      fontWeight: 500,
    },
    author: {
      fontSize: 10,
      fontFamily: 'Roboto',
      // textAlign: 'center',
      marginBottom: 6,
      marginTop: 2,
    },
    subauthor: {
      fontSize: 10,
      fontFamily: 'Roboto',
      // textAlign: 'center',
      marginBottom: 6,
      marginTop: 2,
    },
    liMb: {
      marginBottom: 5,
      fontSize: 10,
      marginLeft: 12,
      fontFamily: 'Roboto',
    },
    subpageNumber: {
      fontFamily: 'Roboto',
      fontSize: 10,
      paddingLeft: 12,

    },
    hyphenContainer: {
      flexDirection: 'row',
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'space-between',
      paddingBottom: 2,
    },
    hyphen: {
      fontFamily: 'Roboto',
      fontSize: 10,
      color: '#0f597d',
      marginLeft: 5,
      marginRight: 5,
    },
    pageNumber: {
      fontFamily: 'Roboto',
      fontSize: 10,
      color: '#0f597d',
    },
    horizontalLine: {
      borderBottomColor: '#0f597d',
      borderBottomWidth: 1,
      flex: 1,
      marginLeft: 10,
      borderBottomStyle: 'dotted',
      marginRight: 10,
    },
    subhorizontalLine: {
      borderBottomWidth: 1,
      flex: 1,
      borderBottomStyle: 'dotted',
      marginLeft: 10,
      marginRight: 10,
    },
    container: {
      borderLeft: '1 solid #000',
      borderRight: '1 solid #000',
      borderTop: 'none',
      borderBottom: 'none',
      padding: 14,
      minHeight: '88vh',
    },
    header: {
      fontSize: 12,
      marginBottom: 20,
      textAlign: 'center',
      color: 'grey',
    },
  });
  const getPageNumber = (title) => {
    const data = tmpTableOfContents?.find((item) => item?.title === title);
    return data?.pageNumber || null;
  };
  const getPageSubNumber = (title) => {
    const data = tableOfSubContents?.find((item) => item?.title === title);
    return data?.pageNumber || null;
  };
  return (
    <>
      <View style={Styles.container}>
        <Text style={Styles.header} fixed />
        <Text style={Styles.title}>Table of Contents</Text>
        <View style={Styles.author}>
          <View style={Styles.hyphenContainer}>
            <Text style={Styles.pageNumber}>Personal Information</Text>
            <View style={Styles.horizontalLine} />
            <Text style={Styles.pageNumber}>{getPageNumber('PersonalData')}</Text>
          </View>

        </View>
        {binderData?.map((item) => (
          <>
            <View style={Styles.author}>
              <View style={Styles.hyphenContainer}>
                <Text style={Styles.pageNumber}>{`Category: ${item?.title}`}</Text>
                <View style={Styles.horizontalLine} />
                <Text style={Styles.pageNumber}>{`${getPageNumber(item?.title)}`}</Text>
              </View>

            </View>
            <View style={Styles.tableSubBullets}>
              {item?.topics?.map((binder) => (
                <View style={Styles.subauthor}>
                  {' '}
                  <View style={Styles.hyphenContainer}>
                    <Text style={Styles.subpageNumber}>{`Topic: ${binder?.title}`}</Text>
                    <View style={Styles.subhorizontalLine} />
                    <Text style={Styles.subpageNumber}>{getPageSubNumber(binder?.title)}</Text>
                  </View>

                </View>
              ))}
            </View>
          </>
        ))}
        <View style={Styles.author}>
          <View style={Styles.hyphenContainer}>
            <Text style={Styles.pageNumber}>Category: Key Contacts</Text>
            <View style={Styles.horizontalLine} />
            <Text style={Styles.pageNumber}>{getPageNumber('Keycontact')}</Text>
          </View>

        </View>
      </View>
      <Footer />
    </>
  );
}
Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: 'https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Mu5GxP.ttf',
    },
    {
      src: 'https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmWUlvAx05IsDqlA.ttf',
      fontWeight: 500,
    },
  ],
});
