import React, { useState } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { Button } from 'react-bootstrap';
import Popover from 'react-bootstrap/Popover';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import ImgLogout from '../../assests/images/logout.svg';
import AuthHelper from '../../utils/AuthHelper';
import { setOpen } from '../../redux/slices/sideBarDataSlice';
import { setAuthToken } from '../../redux/slices/userDataSlice';

export default function Logout() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);

  const popover = (
    <Popover id="popover-basic">
      <Popover.Header as="h3">Confirm Logout</Popover.Header>
      <Popover.Body>
        Are you sure you want to log out?
        <div className="d-flex justify-content-end mt-2">
          <Button
            onClick={() => {
              setShow(false);
            }}
            className="btn btn-primary py-1 px-3 ms-1"
            style={{ width: '60px' }}
          >
            No
          </Button>
          <Button
            onClick={() => {
              dispatch(setOpen(false));
              setShow(false);
              dispatch(setAuthToken(false));
              navigate('/login');
              AuthHelper.clearAll();
            }}
            className="btn btn-danger py-1 px-3 ms-1"
            style={{ width: '60px' }}
          >
            Yes
          </Button>
        </div>
      </Popover.Body>
    </Popover>
  );
  return (
    <div className="logout pointer">
      <OverlayTrigger
        trigger="click"
        placement="top"
        overlay={popover}
        show={show}
      >
        <a
          href
          onClick={() => {
            setShow(true);
          }}
        >
          <img src={ImgLogout} alt="home" width="23" />
          <span>Logout</span>
        </a>
      </OverlayTrigger>
    </div>
  );
}
