/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import {
  View, Text, Font, StyleSheet,
} from '@react-pdf/renderer';
// import Styles from '../styles/Style';

export default function TableContent({ binderData }) {
  const Styles = StyleSheet.create({
    // ....................
    title: {
      fontSize: 20,
      color: '#0f597d',
      // textAlign: 'center',
      fontFamily: 'Open Sans',
      marginBottom: 10,
    },
    author: {
      fontSize: 16,
      fontFamily: 'Open Sans',
      // textAlign: 'center',
      marginBottom: 6,
      marginTop: 2,
    },
    liMb: {
      marginBottom: 5,
      fontSize: 12,
      marginLeft: 12,
      fontFamily: 'Open Sans',
    },
  });
  return (
    <>
      <Text style={Styles.title}>Table of Contents</Text>
      <Text style={Styles.author}>Personal Information </Text>
      {binderData?.map((item) => (
        <>
          <Text style={Styles.author}>
            Category:
            {' '}
            {item?.title}
          </Text>
          <View style={Styles.tableSubBullets}>
            {item?.topics?.map((binder) => (
              <Text style={Styles.liMb}>{binder?.title}</Text>
            ))}
          </View>
        </>
      ))}
    </>
  );
}
Font.register({
  family: 'Open Sans',
  fonts: [
    {
      src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf',
    },
    {
      src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf',
      fontWeight: 600,
    },
  ],
});
