import React, { useEffect, useState } from 'react';
import Subscriptions from '../../../utils/api/services/subscriptionService';

export default function Plan() {
  const [planType, setPlanType] = useState(null);
  const [planName, setPlanName] = useState(null);
  const viewPlan = async () => {
    try {
      const response = await Subscriptions.viewPlan();
      if (response?.status === 200) {
        if (response.data.planType === 0 || response.data.planType === 1) {
          setPlanType(response.data.planType);
          setPlanName(response.data.title);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    viewPlan();
  }, []);

  return (
    <>
      {' '}
      {planType === 1 && (
      <div className="trial">
        {' '}
        {planName}
        {' '}
      </div>
      )}
      {planType === 0 && (
        <div className="premium">
          {' '}
          {planName}
          {' '}
          💎
          {' '}
        </div>
      )}

    </>
  );
}
