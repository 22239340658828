/* eslint-disable comma-dangle */
import { lazy } from 'react';

const routes = [
  {
    path: '/estateplanningBanner/:id/:uuid',
    element: lazy(() => import('../components/Banners/EstatePlanningBanner')),
    auth: true
  },
  {
    path: '/assetsBanner/:id/:uuid',
    element: lazy(() => import('../components/Banners/AssetsBanner')),
    auth: true
  },
  {
    path: '/liabiltiesBanner/:id/:uuid',
    element: lazy(() => import('../components/Banners/LiabiltiesBanner')),
    auth: true
  },
  {
    path: '/fortheHomeBanner/:id/:uuid',
    element: lazy(() => import('../components/Banners/FortheHome')),
    auth: true
  },

  {
    path: '/specialItemsBanner/:id/:uuid',
    element: lazy(() => import('../components/Banners/SpecialItem')),
    auth: true
  },
  {
    path: '/importantDocumentsBanner/:id/:uuid',
    element: lazy(() => import('../components/Banners/ImportantDocument')),
    auth: true
  },
  {
    path: '/catgoryListBox/:id/:uuid',
    element: lazy(() => import('../components/CategoryListPlanning/CategoryPlanning')),
    auth: true
  },
];
export default routes;
