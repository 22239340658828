/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable max-len */
import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import email from '../../../assests/images/icons8-envelope-967.png';
import phone from '../../../assests/images/icons8-phone-967.png';

export default function HelpModal({ setShow }) {
  return (
    <Modal
      show
      backdrop="static"
      keyboard={false}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={() => setShow(false)}
    >
      <Modal.Header className="p-4">
        <Modal.Title id="contained-modal-title-vcenter">
          <h3 className="text-prmary fw-bold my-0 d-flex align-items-center">
            Need help? We are here for you.
          </h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex justify-content-center gap-4">
          <div className="card" style={{ width: '18rem', alignItems: 'center' }}>
            <img className="card-img-top" src={email} alt="Card image cap" style={{ width: '50%' }} />
            <div className="card-body d-flex align-items-center flex-column">
              <h5 className="card-title">Email us</h5>
              <a href="mailto:support@chronwise.com" className="text-center mt-1 text-blue">
                support@chronwise.com
              </a>
            </div>
          </div>
          <div className="card" style={{ width: '18rem', alignItems: 'center' }}>
            <img className="card-img-top" src={phone} alt="Card image cap" style={{ width: '50%' }} />
            <div className="card-body d-flex align-items-center flex-column">
              <h5 className="card-title">Call us</h5>
              <p className="text-center mt-1 text-blue">
                +1 609-200-6006
                <br />
                Monday - Friday
                <br />
                9am - 5pm Eastern Time
              </p>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="btn btn-primary"
          onClick={() => {
            setShow(false);
          }}
        >
          Close
        </Button>

      </Modal.Footer>
    </Modal>
  );
}
