/* eslint-disable comma-dangle  */
import { lazy } from 'react';

const routes = [
  {
    path: '/dashboard',
    element: lazy(() => import('../pages/dashboard')),
    auth: true
  },
  {
    path: '/search',
    element: lazy(() => import('../pages/search/search')),
    auth: true
  },
  {
    path: '/infoBinder',
    element: lazy(() => import('../pages/infoBinder')),
    auth: true
  },
  {
    path: '/infobinder/print',
    element: lazy(() => import('../components/infoSummary/Modal')),
    auth: true
  },
  {
    path: '/categorylist/:id/:uuid',
    element: lazy(() => import('../pages/categoryListPlanning')),
    auth: true
  },
  {
    path: '/category/:id/:uuid',
    element: lazy(() => import('../pages/categoryPlanning')),
    auth: true
  },
  {
    path: '/estatePlanning/:id/:uuid',
    element: lazy(() => import('../pages/essentialDocs')),
    auth: true
  },
  {
    path: '/keyContacts',
    element: lazy(() => import('../pages/keyContacts')),
    auth: true
  },
  {
    path: '/keyContacts/:id/:uuid',
    element: lazy(() => import('../pages/keyContacts')),
    auth: true
  },
  {
    path: '/personalInfo',
    element: lazy(() => import('../pages/personalInfo/Info')),
    auth: true
  },
  {
    path: '/accountInfo',
    element: lazy(() => import('../pages/accountInfo/Info')),
    auth: true
  },
  {
    path: '/testatorInfo',
    element: lazy(() => import('../pages/TestatorInfo/Info')),
    auth: true
  },
  {
    path: '/personal',
    element: lazy(() => import('../pages/personalInfo/Info')),
    auth: true
  },
  {
    path: '/keyContacts/add',
    element: lazy(() => import('../pages/keyContacts/AddContact')),
    auth: true
  },
  {
    path: '/keyContacts/edit/:id',
    element: lazy(() => import('../pages/keyContacts/AddContact')),
    auth: true
  },
  {
    path: '/inviteContact/edit/:id',
    element: lazy(() => import('../pages/keyContacts/AddContact')),
    auth: true
  },
  {
    path: '/manageAccess',
    element: lazy(() => import('../pages/manageAccess/index')),
    auth: true
  },
  {
    path: '/testator/ManageAccess',
    element: lazy(() => import('../pages/TestatormanageAccess/index')),
    auth: true
  },
  {
    path: '/testator/periodic',
    element: lazy(() => import('../pages/PermissionPeriodicReminder')),
    auth: true
  },
  {
    path: '/manageAccess/:id',
    element: lazy(() => import('../pages/manageAccess/Permissions')),
    auth: true
  },
  {
    path: '/addMessage',
    element: lazy(() => import('../pages/manageAccess/Message')),
    auth: true
  },
  {
    path: '/myRoles',
    element: lazy(() => import('../pages/roles')),
    auth: true
  },
  {
    path: '/myRoles/reportAsDeceased',
    element: lazy(() => import('../pages/roles/ReportDeceased')),
    auth: true
  },
  {
    path: '/myRoles/reportAsDeceased/uploadDocs',
    element: lazy(() => import('../pages/roles/UploadDocs')),
    auth: true
  },
  {
    path: '/mangeAccess/infoBinder/:id',
    element: lazy(() => import('../pages/InfoBinderAccess/InfoAccessBinder')),
    auth: true
  },
  {
    path: '/infoBinderRole',
    element: lazy(() => import('../pages/InfoRoleBinder/InforRole')),
    auth: true
  },
  {
    path: '/banner',
    element: lazy(() => import('../components/Banners/WillBanner')),
    auth: true
  },
  {
    path: '/banners',
    element: lazy(() => import('../components/Banners/WizardCompletion')),
    auth: true
  },
  {
    path: '/plans',
    element: lazy(() => import('../pages/pricing/index')),
    auth: true
  },
  {
    path: '/renew-plan',
    element: lazy(() => import('../pages/pricing/renew')),
    auth: true
  },
  {
    path: '/payment',
    element: lazy(() => import('../pages/pricing/payment')),
    auth: true
  },
  {
    path: '/payment-success',
    element: lazy(() => import('../pages/pricing/paymentSuccess')),
    auth: true
  },
  {
    path: '/audit',
    element: lazy(() => import('../pages/audit')),
    auth: true
  },
  {
    path: '/periodic',
    element: lazy(() => import('../pages/periodic')),
    auth: true
  },

];
export default routes;
