/* eslint-disable comma-dangle */
import endpoints from '../endpoints';
import client from '../config/authClient';

const getSearchString = (s) => {
  if (s && s !== '') {
    return `&strSearchData=${s}`;
  }
  return '';
};
const gettestatorId = (id) => {
  if (id && id !== '') {
    return `&testatorId=${id}`;
  }
  return '';
};
const getkeycontactTestatorId = (id) => {
  if (id && id !== '') {
    return `?testatorId=${id}`;
  }
  return '';
};
const getMode = (id) => {
  if (id && id !== '') {
    return `&keyContactCategoryCode=${id}`;
  }
  return '';
};
const getisAfterDeath = (id) => {
  if (id !== undefined && id !== '') {
    return `&isAfterDeath=${id}`;
  }
  return '';
};
const getviewUserId = (id) => {
  if (id && id !== '') {
    return `&viewUserId=${id}`;
  }
  return '';
};
const getuserId = (id) => {
  if (id && id !== '') {
    return `&userId=${id}`;
  }
  return '';
};
const getkeyContactId = (id) => {
  if (id && id !== '') {
    return `&keyContactId=${id}`;
  }
  return '';
};

const getExport = (exp) => {
  if (exp && exp !== '') {
    return `&export=${exp}`;
  }
  return '';
};
const Contacts = {
  getCategories: () => client.get(endpoints.keyContacts.getCategories),
  getSearchUser: (s, testatorId) => client.get(
    `${endpoints.keyContacts.contactList}?strSearchData=${s}${gettestatorId(
      testatorId
    )}`
  ),
  getTestatorSearchUser: (s, testatorId) => client.get(
    `${endpoints.keyContacts.testatorcontactList}?strSearchData=${s}${gettestatorId(
      testatorId
    )}`
  ),
  getTestatorContactList: (
    offset,
    limit,
    strSearchData,
    testatorId,
    keyContactCategoryCode,
    param
  ) => client.get(`${
    endpoints.keyContacts.testatorcontactList
  }?offset=${offset}&limit=${limit}${getSearchString(
    strSearchData
  )}${gettestatorId(testatorId)}${getMode(keyContactCategoryCode)}${getkeyContactId(param?.keyContactId)}${getisAfterDeath(param?.isAfterDeath)}${getviewUserId(param?.viewUserId)}${getuserId(param?.userId)}`),

  getContactList: (
    offset,
    limit,
    strSearchData,
    testatorId,
    keyContactCategoryCode,
    param
  ) => client.get(`${
    endpoints.keyContacts.contactList
  }?offset=${offset}&limit=${limit}${getSearchString(
    strSearchData
  )}${gettestatorId(testatorId)}${getMode(keyContactCategoryCode)}${getkeyContactId(param?.keyContactId)}${getisAfterDeath(param?.isAfterDeath)}${getviewUserId(param?.viewUserId)}${getuserId(param?.userId)}${getExport(param?.export)}`),

  getContactData: (id, testatorId, param) => client.get(
    `${endpoints.keyContacts.keyContact}?id=${id}${getkeyContactId(param?.keyContactId)}${gettestatorId(testatorId)}${getisAfterDeath(param?.isAfterDeath)}${getviewUserId(param?.viewUserId)}${getuserId(param?.userId)}`
  ),
  register: (body, testatorId) => client.post(
    `${endpoints.keyContacts.keyContact}${getkeycontactTestatorId(
      testatorId
    )}`,
    body
  ),
  update: (id, body) => client.put(`${endpoints.keyContacts.keyContact}?id=${id}`, body),
  updateImage: (body) => client.put(`${endpoints.keyContacts.profileImage}`, body),
  delete: (id, param) => client.delete(`${endpoints.keyContacts.keyContact}?id=${id}${getkeyContactId(param?.keyContactId)}${getisAfterDeath(param?.isAfterDeath)}${getviewUserId(param?.viewUserId)}${getuserId(param?.userId)}`),
  uploadFiles: (body) => client.post(endpoints.keyContacts.upload, body),
  deleteFile: (id, testatorId) => client.delete(
    `${endpoints.keyContacts.upload}?id=${id}${gettestatorId(testatorId)}`
  ),
  getFileDownloadLink: (id, testatorId) => client.get(
    `${endpoints.keyContacts.upload}/${id}${getkeycontactTestatorId(
      testatorId
    )}`
  ),
  getUserData: (id) => client.get(`${endpoints.keyContacts.keyContact}/${id}`),
  getPermissions: (id, testatorId) => client.get(
    `${endpoints.keyContacts.permissions}/${id}${getkeycontactTestatorId(
      testatorId
    )}`
  ),
  getPermissionUsersList: (testatorId) => client.get(
    `${endpoints.keyContacts.permissions}${getkeycontactTestatorId(
      testatorId
    )}`
  )
};
export default Contacts;
