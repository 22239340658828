/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  infoBinder: [],
  loading: false,
  binderLoading: false,
  binderData: [],
  printSuccessMsg: { istrue: false, mssg: '' },
  reviewClick: false,
  printKeyContact: [],
  InfoLoad: false,
  userData: [],
};

export const infoDataSlice = createSlice({
  name: 'getData',
  initialState,
  reducers: {
    setInfoBinder: (state, action) => {
      state.infoBinder = action.payload;
    },
    setInfoBinderloading: (state, action) => {
      state.loading = action.payload;
    },
    setBinderLoading: (state, action) => {
      state.binderLoading = action.payload;
    },
    setBinderData: (state, action) => {
      state.binderData = action.payload;
    },
    setPrintSuccessMsg: (state, action) => {
      state.printSuccessMsg = action.payload;
    },
    setIsReview: (state, action) => {
      state.reviewClick = action.payload;
    },
    setPrintKeyContact: (state, action) => {
      state.printKeyContact = action.payload;
    },
    setInfoLoad: (state, action) => {
      state.InfoLoad = action.payload;
    },
    setUserInfo: (state, action) => {
      state.userData = action.payload;
    },
  },
});

export const {
  setUserInfo, setInfoLoad, setInfoBinder, setPrintKeyContact, setIsReview, setInfoBinderloading, setBinderLoading, setBinderData, setPrintSuccessMsg,
} = infoDataSlice.actions;

export default infoDataSlice.reducer;
