import jwtDecode from 'jwt-decode';
import store, { logoutAction } from '../redux/store';

class AuthHelper {
  static #authTokenKey = 'AuthToken';

  static #user = 'User';

  static #rememberme = 'RememberMe';

  static #sessionUrl = 'sessionUrl';

  static #subsctiption = 'Subscription';

  /**
   * Set the AuthToken value
   *
   * @param {string} token
   * @returns {void}
   */
  static setAuthToken(token) {
    return localStorage.setItem(this.#authTokenKey, token);
  }

  /**
   * Set the user data
   *
   * @param {string} token
   * @returns {void}
   */
  static setUser(user) {
    return localStorage.setItem(this.#user, JSON.stringify(user));
  }

  static setSubscription(subsctiption) {
    return localStorage.setItem(
      this.#subsctiption,
      JSON.stringify(subsctiption),
    );
  }

  /**
   * Getting the AuthToken
   *
   * @returns {string}
   */
  static getSubscriptionInfo() {
    const subrscription = JSON.parse(localStorage.getItem('Subscription'));
    return subrscription;
  }

  static getUserInfo() {
    const user = JSON.parse(localStorage.getItem('User'));
    return user;
  }

  /**
   * Getting the AuthToken
   *
   * @returns {string}
   */
  static getAuthToken() {
    return localStorage.getItem(this.#authTokenKey);
  }

  /**
   * Getting the AuthToken
   *
   * @returns {string}
   */
  static getLocalStorageItem(item) {
    return JSON.parse(localStorage.getItem(item));
  }

  /**
   * Clearing the AuthToken from local storage
   *
   * @returns {void}
   */
  static clearAuthToken() {
    return localStorage.removeItem(this.#authTokenKey);
  }

  /**
   * Clearing the AuthToken from local storage
   *
   * @returns {void}
   */
  static putRememberMe(rememberme) {
    return localStorage.setItem(this.#rememberme, JSON.stringify(rememberme));
  }

  /**
   * Getting the Rememberme
   *
   * @returns {string}
   */
  static getRememberMe() {
    return JSON.parse(sessionStorage.getItem('rememberme'));
  }

  /**
   * Clearing the RememberMe data from local storage
   *
   * @returns {void}
   */
  static ClearRememberMe() {
    return localStorage.removeItem(this.#rememberme);
  }

  /**
   * Clearing the user data from local storage
   *
   * @returns {void}
   */
  static clearUser() {
    return localStorage.removeItem(this.#user);
  }

  /**
   * Clearing data from local storage
   *
   * @returns {void}
   */
  static clearAll() {
    localStorage.clear();
    store.dispatch(logoutAction());
    sessionStorage.removeItem('Wizardtitle');
    sessionStorage.removeItem('isReview');
    sessionStorage.removeItem('selectedPlan');
    sessionStorage.removeItem('clientSecret');
    sessionStorage.removeItem('sessionUrl');
  }

  /**
   * It is used for check whether a user is logged in or not
   *
   * @returns {boolean}
   */
  static validToken() {
    try {
      const token = this.getAuthToken();
      jwtDecode(token);
      if (jwtDecode(token).exp < Date.now() / 1000) {
        localStorage.clear();
        return false;
      }
      return true;
    } catch (errorObj) {
      return false;
    }
  }
}

export default AuthHelper;
