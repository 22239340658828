/* eslint-disable no-unused-vars */
import React from 'react';
import { useSelector } from 'react-redux';
import Badge from 'react-bootstrap/Badge';
import defaultAvatar from '../../../assests/images/profile_avatar.png';
import useutils from '../../../utils/index';

export default function Profile() {
  const { pascalText, getGreeting } = useutils();
  const { user } = useSelector((state) => state.user) || {};
  return (
    <div className="profile-det">
      <div className="profile-pic me-2">
        <img
          src={user?.imageFile || defaultAvatar}
          alt="profile"
          onError={(e) => {
            e.target.src = defaultAvatar;
          }}
        />
      </div>
      <div className="profile-content">
        <p className="mb-1">
          {getGreeting()}
          ,
        </p>
        <h5 className="mb-0">
          {pascalText(`${user?.firstName || ''} ${user?.middleName || ''}  ${user?.lastName || ''}`)}
        </h5>
      </div>
    </div>
  );
}
