/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  wizardinfo: undefined,
};

export const wizardDataSlice = createSlice({
  name: 'wizardinfo',
  initialState,
  reducers: {
    setWizardinfo: (state, action) => {
      state.wizardinfo = action.payload;
    },
  },
});

export const { setWizardinfo } = wizardDataSlice.actions;

export default wizardDataSlice.reducer;
