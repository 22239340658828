/* eslint-disable comma-dangle */
import endpoints from '../endpoints';
import client from '../config/authClient';
import { get, post } from '../config/apiClient';

const getkeycontactTestatorId = (id) => {
  if (id && id !== '') { return `?testatorId=${id}`; }
  return '';
};
const Permissions = {
  getPermissions: (id, testatorId) => client.get(`${endpoints.permissions.permission}/${id}${getkeycontactTestatorId(testatorId)}`),
  getInvite: (id) => get(`${endpoints.permissions.inviteResponse}?key=${id}`),
  getInviteDetails: (id) => client.get(`${endpoints.permissions.roleDetails}?permissionId=${id}`),
  updateInvite: (body) => post(endpoints.permissions.inviteResponse, body),
  setPermissions: (body) => client.post(`${endpoints.permissions.permission}`, body),
  editPermissions: (body) => client.put(endpoints.permissions.permission, body),
  inviteUser: (body) => client.post(endpoints.permissions.invite, body),
  revokeAccess: (body) => client.delete(endpoints.permissions.permission, { data: { ...body } }),
  getRoles: () => client.get(endpoints.permissions.roles),
  reportAsDeceased: (body) => client.post(endpoints.permissions.reportDeceased, body),
  lastLogin: (body) => client.post(endpoints.permissions.lastLogin, body),
  QuickPermission: (body) => client.post(endpoints.permissions.QuickPermission, body),
};
export default Permissions;
