/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  categoryTitle: undefined,
};

export const categoryDataSlice = createSlice({
  name: 'setCategory',
  initialState,
  reducers: {
    setCategoryTitle: (state, action) => {
      state.categoryTitle = action.payload;
    },
  },
});

export const { setCategoryTitle } = categoryDataSlice.actions;

export default categoryDataSlice.reducer;
