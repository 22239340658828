import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

export default function InfoModal({ setShow }) {
  return (
    <Modal
      show
      backdrop="static"
      keyboard={false}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={() => setShow(false)}
    >
      <Modal.Header className="p-4">
        <Modal.Title id="contained-modal-title-vcenter">
          <h3 className="text-prmary fw-bold my-0 d-flex align-items-center">
            Note
          </h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          This feature is part of our product roadmap and will be coming in future product releases.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="btn btn-primary"
          onClick={() => {
            setShow(false);
          }}
        >
          Okay
        </Button>

      </Modal.Footer>
    </Modal>
  );
}
