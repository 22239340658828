/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  search: [],
  searchloading: false,
  searchBinderData: [],
  searchParentBinderData: [],
  searchKey: '',
};

export const searchDataSlice = createSlice({
  name: 'getData',
  initialState,
  reducers: {
    setSearch: (state, action) => {
      state.search = action.payload;
    },
    setSearchloading: (state, action) => {
      state.searchloading = action.payload;
    },
    setSearchBinderData: (state, action) => {
      state.searchBinderData = action.payload;
    },
    setSearchParentBinderData: (state, action) => {
      state.searchParentBinderData = action.payload;
    },
    setSearchKey: (state, action) => {
      state.searchKey = action.payload;
    },
  },
});

export const {
  setSearch, setSearchloading, setSearchBinderData, setSearchParentBinderData, setSearchKey,
} = searchDataSlice.actions;

export default searchDataSlice.reducer;
