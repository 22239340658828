// ** Routes Imports
import LoginRoutes from './LoginRoutes';
import DashboardRoutes from './DashboardRoutes';
import BannerRoutes from './BannerPageRoute';
// ** Default Route
const DefaultRoute = '/login';

// ** Merge Routes
const RouteList = [...LoginRoutes, ...DashboardRoutes, ...BannerRoutes];

export { DefaultRoute, RouteList };
