/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedContact: undefined,
  permissionDetails: undefined,
  accessMember: false,
  isAfterDeath: 0,
};

export const permissionDataSlice = createSlice({
  name: 'permissionData',
  initialState,
  reducers: {
    setSelectedContact: (state, action) => {
      state.selectedContact = action.payload;
    },
    clearSelectedContact: (state) => {
      state.selectedContact = undefined;
    },
    setPermissionDetails: (state, action) => {
      state.permissionDetails = action.payload;
    },
    setABDepth: (state, action) => {
      state.isAfterDeath = action.payload;
    },
    setAccessMember: (state, action) => {
      state.accessMember = action.payload;
    },
    clearPermissions: (state) => {
      state.selectedContact = undefined;
      state.permissionDetails = undefined;
    },
  },
});

export const {
  setPermissionDetails,
  clearPermissions,
  setSelectedContact,
  clearSelectedContact,
  setAccessMember,
  setABDepth,
} = permissionDataSlice.actions;

export default permissionDataSlice.reducer;
