import { StyleSheet } from '@react-pdf/renderer';

const Styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
    fontFamily: 'Open Sans',
  },
  title: {
    fontSize: 24,
    textAlign: 'center',
    fontFamily: 'Open Sans',
  },
  author: {
    fontSize: 20,
    textAlign: 'center',
    marginBottom: 40,
    marginTop: 20,
    fontFamily: 'Open Sans',
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
    fontFamily: 'Open Sans',
  },
  questiontext: {
    margin: 12,
    fontSize: 16,
    textAlign: 'justify',
    fontFamily: 'Open Sans',
  },
  text: {
    margin: 12,
    fontSize: 16,
    textAlign: 'justify',
    fontFamily: 'Open Sans',
  },
  textHead: {
    margin: 12,
    fontSize: 18,
    fontWeight: 'bold',
    fontFamily: 'Open Sans',
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: 'center',
    color: 'grey',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
  cover: {
    paddingTop: 200,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  titlesub: {
    fontSize: 18,
    textAlign: 'center',
    marginTop: 2,
  },
});

export default Styles;
