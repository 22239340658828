/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import { useLocation } from 'react-router-dom';
import Badge from 'react-bootstrap/Badge';
import { useSelector } from 'react-redux';
import { sidebaronRole, sidebarExpAccOnRole } from '../data';

export default function RoleMenuList({
  getSubList, getSubRoleMenuList,
  onClickPermission, hideDrawer, getTestatorManageAccessMenuList,
  getSubTestatorMenuList, onClickMenuItem,
}) {
  const location = useLocation();
  const {
    myroleCount, IsTrustedAccExp,
  } = useSelector((state) => state.roles) || {};
  return (
    <ul className="list-unstyled listmenus">
      {
IsTrustedAccExp ? (
  <>
    {sidebarExpAccOnRole?.map((item) => (item?.name === 'My Information Binder' ? (
      getSubList(item)
    ) : (
      <>
        {item?.name === 'name' ? (
          getSubRoleMenuList(item)
        ) : (
          <>
            {item?.name === 'My Roles'
    || item?.name === 'Manage Access' ? (
      <li className="sidemenu-items">
        <a
          href
          onClick={() => onClickPermission(item)}
          className={
            location?.pathname?.includes(item.url)
              ? 'active pointer'
              : 'pointer'
          }
        >
          <img src={item?.img} alt="home" />
          {item?.name === 'My Roles' ? (
            <>
              <span>{item?.name}</span>
              {myroleCount > 0 && (
                <Badge
                  className="p-badge ms-4"
                  shape="rounded-circle"
                >
                  {myroleCount}
                </Badge>
              )}
            </>
          ) : (
            <span>{item?.name}</span>
          )}
        </a>
      </li>
              ) : (
                <>
                  {item?.name === 'Help' ? (
                    <li className="sidemenu-items">
                      <a
                        href
                        onClick={() => hideDrawer(item?.name)}
                        className={
                location?.pathname?.includes(item.url)
                  ? 'active pointer'
                  : 'pointer'
              }
                      >
                        <img src={item?.img} alt="home" />
                        <span>{item?.name}</span>
                      </a>
                    </li>
                  ) : (
                    <>
                      {item?.name === 'Testator' || item?.name === 'TestatorPeriodic' ? (
                        getTestatorManageAccessMenuList(item)
                      ) : (
                        <>
                          {item?.name === 'TestatorProfile' ? (
                            getSubTestatorMenuList(item)
                          ) : (
                            <li className="sidemenu-items">
                              <a
                                href
                                onClick={() => onClickMenuItem(item)}
                                className={
                        location?.pathname?.includes(item.url)
                          ? 'active pointer'
                          : 'pointer'
                      }
                              >
                                <img src={item?.img} alt="home" />
                                <span>{item?.name}</span>
                              </a>
                            </li>
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              )}
          </>
        )}
      </>
    )))}
  </>
) : (
  <>
    {sidebaronRole?.map((item) => (item?.name === 'My Information Binder' ? (
      getSubList(item)
    ) : (
      <>
        {item?.name === 'name' ? (
          getSubRoleMenuList(item)
        ) : (
          <>
            {item?.name === 'My Roles'
        || item?.name === 'Manage Access' ? (
          <li className="sidemenu-items">
            <a
              href
              onClick={() => onClickPermission(item)}
              className={
                location?.pathname?.includes(item.url)
                  ? 'active pointer'
                  : 'pointer'
              }
            >
              <img src={item?.img} alt="home" />
              {item?.name === 'My Roles' ? (
                <>
                  <span>{item?.name}</span>
                  {myroleCount > 0 && (
                    <Badge
                      className="p-badge ms-4"
                      shape="rounded-circle"
                    >
                      {myroleCount}
                    </Badge>
                  )}
                </>
              ) : (
                <span>{item?.name}</span>
              )}
            </a>
          </li>
              ) : (
                <>
                  {item?.name === 'Help' ? (
                    <li className="sidemenu-items">
                      <a
                        href
                        onClick={() => hideDrawer(item?.name)}
                        className={
                    location?.pathname?.includes(item.url)
                      ? 'active pointer'
                      : 'pointer'
                  }
                      >
                        <img src={item?.img} alt="home" />
                        <span>{item?.name}</span>
                      </a>
                    </li>
                  ) : (
                    <>
                      {item?.name === 'Testator' || item?.name === 'TestatorPeriodic' ? (
                        getTestatorManageAccessMenuList(item)
                      ) : (
                        <>
                          {item?.name === 'TestatorProfile' ? (
                            getSubTestatorMenuList(item)
                          ) : (
                            <li className="sidemenu-items">
                              <a
                                href
                                onClick={() => onClickMenuItem(item)}
                                className={
                            location?.pathname?.includes(item.url)
                              ? 'active pointer'
                              : 'pointer'
                          }
                              >
                                <img src={item?.img} alt="home" />
                                <span>{item?.name}</span>
                              </a>
                            </li>
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              )}
          </>
        )}
      </>
    )))}
  </>
)
      }

    </ul>
  );
}
