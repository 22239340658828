/* eslint-disable comma-dangle */
import ImgHome from '../../assests/images/home.svg';
import ImgPersonal from '../../assests/images/personal-info.svg';
import ImgAccess from '../../assests/images/access.svg';
import ImgRoles from '../../assests/images/role.svg';
import ImgBinder from '../../assests/images/info-binder.svg';
import ImgClock from '../../assests/images/icons8-clock.svg';
import ImgHelp from '../../assests/images/help.svg';
import ImgAudit from '../../assests/images/audit.png';
import ImgPrint from '../../assests/images/printer.png';

export const footerList = [
  {
    name: 'Terms of Service',
    url: 'https://www.chronwise.com/terms-of-service'
  },
  {
    name: 'Privacy policy',
    url: 'https://www.chronwise.com/privacy-policy'
  },
  {
    name: 'Security',
    url: 'https://www.chronwise.com/security'
  },
  {
    name: 'Give Feedback',
    url: 'feedback@chronwise.com'
  }
];
export const sidebar = [
  {
    name: 'Home',
    url: '/dashboard',
    img: ImgHome
  },
  {
    name: 'My Information Binder',
    url: '/infoBinder',
    img: ImgBinder,
    submenu: [
      { name: 'Estate Planning', url: '/estatePlanning' },
    ]
  },
  {
    name: 'Account',
    url: '/personalInfo',
    img: ImgPersonal
  }, {
    name: 'Manage Access',
    url: '/manageAccess',
    img: ImgAccess
  },
  {
    name: 'Audit trail',
    url: '/audit',
    img: ImgAudit
  },
  {
    name: 'Periodic Review',
    url: '/periodic',
    img: ImgClock
  },
  {
    name: 'My Roles',
    url: '/myRoles',
    img: ImgRoles
  },
  {
    name: 'Settings',
    url: '/accountInfo',
    img: ImgPersonal
  },
  { name: 'Print Information Binder', url: '/printInfo', img: ImgPrint },
  {
    name: 'Help',
    url: '/help',
    img: ImgHelp
  },
];
export const Testatorsidebar = [
  {
    name: 'Home',
    url: '/dashboard',
    img: ImgHome
  },
  {
    name: 'My Information Binder',
    url: '/infoBinder',
    img: ImgBinder,
    submenu: [
      { name: 'Estate Planning', url: '/estatePlanning' },
    ]
  },
  {
    name: 'Testator',
    url: '/testatorInfo',
    img: ImgPersonal
  },
  {
    name: 'Account',
    url: '/personalInfo',
    img: ImgPersonal
  },
  {
    name: 'Manage Access',
    url: '/manageAccess',
    img: ImgAccess
  },
  {
    name: 'My Roles',
    url: '/myRoles',
    img: ImgRoles
  },
  {
    name: 'Help',
    url: '/help',
    img: ImgHelp
  },
];
export const TestatorManageAccesssidebar = [
  {
    name: 'Home',
    url: '/dashboard',
    img: ImgHome
  },
  {
    name: 'My Information Binder',
    url: '/infoBinder',
    img: ImgBinder,
    submenu: [
      { name: 'Estate Planning', url: '/estatePlanning' },
    ]
  },
  {
    name: 'Testator',
    url: '/testator/ManageAccess',
    img: ImgAccess
  }, {
    name: 'TestatorPeriodic',
    url: '/testator/periodic',
    img: ImgAccess
  },
  {
    name: 'Account',
    url: '/personalInfo',
    img: ImgPersonal
  },
  {
    name: 'Manage Access',
    url: '/manageAccess',
    img: ImgAccess
  },
  {
    name: 'My Roles',
    url: '/myRoles',
    img: ImgRoles
  },
  {
    name: 'Help',
    url: '/help',
    img: ImgHelp
  },
];
export const accExp = [
  {
    name: 'My Roles',
    url: '/myRoles',
    img: ImgRoles
  },
  {
    name: 'Account',
    url: '/personalInfo',
    img: ImgPersonal
  },
];
export const sidebaronRole = [
  {
    name: 'Testator View',
    url: '/dashboard',
    img: ImgHome
  },
  {
    name: 'Testator',
    url: '/testator/ManageAccess',
    img: ImgAccess
  }, {
    name: 'TestatorPeriodic',
    url: '/testator/periodic',
    img: ImgAccess
  },
  {
    name: 'TestatorProfile',
    url: '/testatorInfo',
    img: ImgPersonal
  },
  {
    name: 'name',
    url: '/infoBinderRole',
    img: ImgBinder,
    submenu: [
      { name: 'Estate Planning', url: '/estatePlanning' },
    ]
  },
];
export const sidebarExpAccOnRole = [
  {
    name: 'Testator View',
    url: '/dashboard',
    img: ImgHome
  },
  {
    name: 'TestatorProfile',
    url: '/testatorInfo',
    img: ImgPersonal
  },
];
