/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

export default function IdealTimerModal(props) {
  return (
    <Modal
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          <h2 className="text-prmary fw-bold">
            You have been idle for a while
          </h2>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="text-justify">
          <h5>
            You'll be logged out soon.
          </h5>
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props?.onHide}>Stay Logged In</Button>
        <Button onClick={props?.logout} className="btn btn-danger">Logout</Button>
      </Modal.Footer>
    </Modal>
  );
}
