import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import FooterStyle from '../styles/Footer';

export default function Footer() {
  return (

    <View style={FooterStyle.footer} fixed>
      <Text style={FooterStyle.confidentialText}>Confidential</Text>
      <Text
        style={FooterStyle.pageNumber}
        render={({ pageNumber }) => (
          `Page | ${pageNumber} `
        )}
        fixed
      />
    </View>
  );
}
