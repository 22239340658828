/* eslint-disable no-debugger */
import { configureStore } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';
import permissions from './slices/permissionsDataSlice';
import infoBinder from './slices/infoBinderSlice';
import user from './slices/userDataSlice';
import roles from './slices/roleDataSlice';
import sidebar from './slices/sideBarDataSlice';
import keycontact from './slices/keycontactSlice';
import wizardSlice from './slices/wizardSlice';
import category from './slices/categorySlice';
import search from './slices/searchSlice';

const persistConfig = {
  key: 'root',
  storage,
};
const LOGOUT = 'USER_LOGOUT';
export const logoutAction = () => ({
  type: LOGOUT,
});

const appReducer = combineReducers({
  permissions,
  infoBinder,
  user,
  roles,
  sidebar,
  keycontact,
  wizardSlice,
  category,
  search,
});
const rootReducer = (state, action) => {
  if (action.type === 'USER_LOGOUT') {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};
const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = configureStore({
  reducer: persistedReducer,
});

export default store;
