/* eslint-disable no-unused-vars   */
/* eslint-disable max-len */
/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import {
  Document,
  Font,
  Page,
  StyleSheet,
  Text,
  PDFViewer,
  View,
} from '@react-pdf/renderer';
import { useSelector } from 'react-redux';
import Title from './Components/Title';
import useUtils from '../../../utils';
import AuthHelper from '../../../utils/AuthHelper';
import PersonalData from './Components/PersonalData';
import TableContent from './Components/TableContent';
import GetSingleData from './Components/GetSingleData';
import GetMultipleData from './Components/GetMultipleData';
import Footer from './Components/Footer';
import FooterCover from './Components/FooterCover';

Font.register({
  family: 'Open Sans',
  fonts: [
    { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf' },
    { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-italic.ttf', fontStyle: 'italic' },
  ],
});
const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
    fontFamily: 'Open Sans',
  },
  border: {
    // border: '1 solid #000',
    // padding: 2,
  },
  title: {
    fontSize: 20,
    textAlign: 'center',
  },
  author: {
    fontSize: 15,
    textAlign: 'center',
    marginBottom: 10,
    marginTop: 20,
  },
  subtitle: {
    fontSize: 14,
    margin: 12,
  },
  questiontext: {
    margin: 12,
    fontSize: 12,
    textAlign: 'justify',
    fontStyle: 'italic',
  },
  text: {
    margin: 12,
    fontSize: 12,
    textAlign: 'justify',
  },
  anstext: {
    marginLeft: 12,
    fontSize: 12,
    textAlign: 'justify',
    marginBottom: 10,
  },
  textHead: {
    margin: 12,
    marginBottom: 5,
    fontSize: 14,
    fontWeight: 'bold',
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: 'center',
    color: 'grey',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
  cover: {
    paddingTop: 200,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  titlesub: {
    fontSize: 18,
    textAlign: 'center',
    marginTop: 2,
  },
  pageFooter: {
    position: 'absolute',
    bottom: 30,
    left: 0,
    right: 0,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});
const MyDocument = React.memo(({ isLoading, binderData }) => {
  const user = AuthHelper.getUserInfo();
  const { pascalText } = useUtils();
  // const { binderData } = useSelector((state) => state.infoBinder);
  const today = new Date();
  const isCheck = (data) => {
    if (data?.length === 1) {
      return true;
    }
    return false;
  };
  return (
    <Document>
      <Page style={styles.body}>
        <Text style={styles.cover}>
          <Title />
        </Text>
        <Text style={styles.title}>
          {`${pascalText(
            user?.middleName
              ? `${user?.firstName}  ${user?.middleName} ${user?.lastName}`
              : `${user?.firstName} ${user?.lastName}`,
          )}'s Information Binder`}
        </Text>
        <Text style={styles.titlesub}>
          {' '}
          As Of
          {' '}
          {today.toDateString()}
        </Text>
        <FooterCover />
      </Page>
      <Page style={styles.body}>
        <TableContent binderData={binderData} Styles={styles} />
      </Page>
      <Page style={styles.body}>
        <View style={styles.container}>
          <View style={styles.border}>
            <PersonalData />
          </View>
        </View>
      </Page>
      {binderData?.length > 0 && (
      <>
        <Page style={styles.body}>
          <Text style={styles.header} fixed>
            ~ CronWise ~
          </Text>
          <View style={styles.container}>
            <View style={styles.border}>
              {binderData?.map((item, mainIndex) => (
                <>
                  <Text style={styles.title}>
                    Category:
                    {' '}
                    {item?.title}
                  </Text>
                  {item?.topics?.map((subItem, subItemIndex) => (
                    <>
                      <Text style={styles.author}>
                        Topic:
                        {' '}
                        {subItem?.title}
                      </Text>
                      {subItem?.wizards?.map((wizardItem, index) => (
                        <>
                          {wizardItem?.questions?.map((questionItem) => (
                            <>
                              <Text style={styles.textHead}>
                                {wizardItem?.title}
                              </Text>
                              <Text style={styles.questiontext}>
                                {questionItem?.question}
                              </Text>
                              {questionItem?.subQuestions?.map((subqstn) => subqstn?.answers.map((answerItem) => (
                                <>
                                  <Text style={styles.anstext}>
                                    {answerItem?.answer}
                                  </Text>
                                  {answerItem?.wizards?.length > 0
                                      && answerItem?.wizards?.map(
                                        (wizardsQst) => wizardsQst?.questions?.map(
                                          (answerItems) => (
                                            <>
                                              {isCheck(
                                                answerItems?.subQuestions,
                                              ) ? (
                                                <>
                                                  {' '}
                                                  {answerItems
                                                    ?.subQuestions
                                                    ?.length > 0
                                                      && answerItems?.subQuestions?.map(
                                                        (answerItemsub) => (
                                                          <>
                                                            <Text
                                                              style={
                                                                styles.questiontext
                                                              }
                                                            >
                                                              {
                                                                answerItems.question
                                                              }
                                                            </Text>
                                                            {answerItemsub
                                                              ?.answers
                                                              ?.length
                                                              > 0
                                                              && answerItemsub?.answers?.map(
                                                                (
                                                                  answerobj,
                                                                ) => (
                                                                  <>
                                                                    <Text
                                                                      key="bold"
                                                                      style={
                                                                        styles.text
                                                                      }
                                                                    >
                                                                      {` ${answerobj.answer}`}
                                                                    </Text>
                                                                    {answerobj
                                                                      ?.wizards
                                                                      ?.length
                                                                      > 0
                                                                      && answerobj?.wizards?.map(
                                                                        (
                                                                          answerItemwiz,
                                                                        ) => answerItemwiz?.questions?.map(
                                                                          (
                                                                            answerobjwiz,
                                                                          ) => (
                                                                            <>
                                                                              <Text
                                                                                key={
                                                                                    answerobjwiz.id
                                                                                  }
                                                                                style={
                                                                                    styles.text
                                                                                  }
                                                                              >
                                                                                {[
                                                                                  <Text
                                                                                    key="bold"
                                                                                    style={
                                                                                        styles.text
                                                                                      }
                                                                                  >
                                                                                    {`${answerobjwiz.question}`}
                                                                                  </Text>,
                                                                                ]}
                                                                              </Text>
                                                                              {answerobjwiz?.subQuestions?.map(
                                                                                (
                                                                                  answerItemwizsub,
                                                                                ) => answerItemwizsub?.answers?.map(
                                                                                  (
                                                                                    answerobjwizsub,
                                                                                  ) => (
                                                                                    <Text
                                                                                      key={
                                                                                            answerobjwiz.id
                                                                                          }
                                                                                      style={
                                                                                            styles.text
                                                                                          }
                                                                                    >
                                                                                      {[
                                                                                        <Text
                                                                                          key="bold"
                                                                                          style={
                                                                                                styles.text
                                                                                              }
                                                                                        >
                                                                                          {`${answerobjwizsub.answer}`}
                                                                                        </Text>,
                                                                                      ]}
                                                                                    </Text>
                                                                                  ),
                                                                                ),
                                                                              )}
                                                                            </>
                                                                          ),
                                                                        ),
                                                                      )}
                                                                  </>
                                                                ),
                                                              )}
                                                          </>
                                                        ),
                                                      )}
                                                </>
                                                ) : (
                                                  <>
                                                    <Text
                                                      style={
                                                        styles.questiontext
                                                      }
                                                    >
                                                      {answerItems.question}
                                                    </Text>
                                                    {answerItems
                                                      ?.subQuestions
                                                      ?.length > 0
                                                      && answerItems?.subQuestions?.map(
                                                        (answerItemsub) => (
                                                          <>
                                                            {answerItemsub
                                                              ?.answers
                                                              ?.length
                                                              > 0
                                                              && answerItemsub?.answers?.map(
                                                                (
                                                                  answerobj,
                                                                ) => (
                                                                  <>
                                                                    <Text
                                                                      style={
                                                                        styles.questiontext
                                                                      }
                                                                    >
                                                                      {
                                                                        answerItemsub?.title
                                                                      }
                                                                      :
                                                                      {` ${answerobj.answer}`}
                                                                    </Text>
                                                                    {answerobj
                                                                      ?.wizards
                                                                      ?.length
                                                                      > 0
                                                                      && answerobj?.wizards?.map(
                                                                        (
                                                                          answerItemwiz,
                                                                        ) => answerItemwiz?.questions?.map(
                                                                          (
                                                                            answerobjwiz,
                                                                          ) => (
                                                                            <>
                                                                              <Text
                                                                                key={
                                                                                    answerobjwiz.id
                                                                                  }
                                                                                style={
                                                                                    styles.text
                                                                                  }
                                                                              >
                                                                                {[
                                                                                  <Text
                                                                                    key="bold"
                                                                                    style={
                                                                                        styles.text
                                                                                      }
                                                                                  >
                                                                                    {`• ${answerobjwiz.question}`}
                                                                                  </Text>,
                                                                                ]}
                                                                              </Text>
                                                                              {answerobjwiz?.subQuestions?.map(
                                                                                (
                                                                                  answerItemwizsub,
                                                                                ) => answerItemwizsub?.answers?.map(
                                                                                  (
                                                                                    answerobjwizsub,
                                                                                  ) => (
                                                                                    <Text
                                                                                      key={
                                                                                            answerobjwiz.id
                                                                                          }
                                                                                      style={
                                                                                            styles.text
                                                                                          }
                                                                                    >
                                                                                      {[
                                                                                        <Text
                                                                                          key="bold"
                                                                                          style={
                                                                                                styles.text
                                                                                              }
                                                                                        >
                                                                                          {`${answerobjwizsub.answer}`}
                                                                                        </Text>,
                                                                                      ]}
                                                                                    </Text>
                                                                                  ),
                                                                                ),
                                                                              )}
                                                                            </>
                                                                          ),
                                                                        ),
                                                                      )}
                                                                  </>
                                                                ),
                                                              )}
                                                          </>
                                                        ),
                                                      )}
                                                  </>
                                                )}
                                            </>
                                          ),
                                        ),
                                      )}
                                </>
                              )))}
                            </>
                          ))}
                        </>
                      ))}
                    </>
                  ))}
                </>
              ))}
            </View>
          </View>
          <Footer />
        </Page>
      </>
      )}
    </Document>
  );
});
export default MyDocument;
