import React from 'react';
import {
  StyleSheet, Text, View, Link,
} from '@react-pdf/renderer';
import useUtils from '../../../../utils';

export default function KeycontactLists({ printKeyContact }) {
  const { formatUsPhoneNumber } = useUtils();
  const styles = StyleSheet.create({
    title: {
      fontSize: 12,
      textAlign: 'center',
      fontWeight: 500,
      marginTop: 12,
    },
    author: {
      fontSize: 12,
      textAlign: 'center',
      marginBottom: 12,
      marginTop: 16,
    },
    horizontalLine: {
      borderBottomWidth: 1,
      // flex: 1,
      borderBottomColor: '#A9ACAB',
      marginLeft: 10,
      marginRight: 10,
      marginBottom: 10,
      marginTop: 10,
    },
    questionwrap: {
      marginBottom: 16,
      marginTop: 36,
    },
    typeAnstext: {
      marginLeft: 12,
      fontSize: 10,
      textAlign: 'justify',
      marginBottom: 10,
      marginTop: 10,
      fontWeight: 500,
    },
    typeAnstextSub: {
      marginLeft: 12,
      fontSize: 10,
      textAlign: 'justify',
      marginBottom: 10,
    },
  });
  return (
    <View>
      <Text style={styles.title}>
        Category: Key Contacts

      </Text>
      <View style={styles.questionwrap}>
        {printKeyContact?.map((item) => (
          <View>

            <Text style={styles.typeAnstext}>
              {`${item?.firstName} ${item?.middleName || ''} ${item?.lastName} ${item?.suffix || ''} `}
            </Text>
            {item?.email
            && (
            <Text style={styles.typeAnstextSub}>
              Email:
              {' '}
              <Link src={`mailto:${item?.email}`}>{item?.email}</Link>
            </Text>
            )}
            {item?.relationship
            && (
            <Text style={styles.typeAnstextSub}>
              Relationship:
              {' '}
              {item?.relationship}
            </Text>
            )}
            {item?.companyName
            && (
            <Text style={styles.typeAnstextSub}>
              Organization name:
              {' '}
              {item?.companyName}
            </Text>
            )}
            {item?.phoneNumber
            && (
            <Text style={styles.typeAnstextSub}>
              Phone:
              {' '}
              {item?.phoneNumber && item?.phCountryCode
                ? `+${item?.phCountryCode}${' '}${formatUsPhoneNumber(item?.phCountryCode, item?.phoneNumber)}`
                : '-'}
            </Text>
            )}
            {item?.addressLine1
            && (
            <Text style={styles.typeAnstextSub}>
              Address line 1:
              {' '}
              {item?.addressLine1}
            </Text>
            )}
            {item?.addressLine2
            && (
            <Text style={styles.typeAnstextSub}>
              Address line 2:
              {' '}
              {item?.addressLine2}
            </Text>
            )}
            {item?.country
            && (
            <Text style={styles.typeAnstextSub}>
              Country:
              {' '}
              {item?.country}
            </Text>
            )}
            {item?.state
            && (
            <Text style={styles.typeAnstextSub}>
              State:
              {' '}
              {item?.state}
            </Text>
            )}
            {item?.city
            && (
            <Text style={styles.typeAnstextSub}>
              City:
              {' '}
              {item?.city}
            </Text>
            )}
            {item?.zipcode

            && (
            <Text style={styles.typeAnstextSub}>
              Zip/Postal code:
              {' '}
              {item?.zipcode}
            </Text>
            )}
            <Text style={styles?.horizontalLine} />
          </View>
        ))}

      </View>
    </View>
  );
}
