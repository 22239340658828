import React from 'react';
import { AiOutlineCopyright } from 'react-icons/ai';
import { footerList } from './data';

export default function Footer() {
  return (
    <div className="footer">
      <ul>
        {footerList?.map((item) => (
          <li key={item?.name}>
            {item?.name === 'Give Feedback' ? <a href={`mailto:${item?.url}`}>{item?.name}</a>
              : <a href={item?.url} target="_blank" rel="noreferrer">{item?.name}</a>}
          </li>
        ))}
      </ul>
      <p className="text-center mb-2">
        <AiOutlineCopyright style={{ fontSize: '15px', marginBottom: '2px' }} />
        {' '}
        Copyright 2024 ChronWise. All Rights reserved
      </p>
    </div>
  );
}
