/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable react/jsx-no-useless-fragment  */
import React from 'react';
import {
  Document,
  Font,
  Page,
  StyleSheet,
  Text,
  PDFViewer,
  View,
} from '@react-pdf/renderer';
import Title from './Title';
import PersonalData from './PersonalData';
import Footer from './Footer';
import TableContent from './TableContent';
import FooterCover from '../../common/Modal/Components/FooterCover';
import PageHead from './subComponents/PageHead';
import Question from './subComponents/Question';
import SubQuestion from './subComponents/SubQuestion';
import CoverPage from './subComponents/CoverPage';
import BodyPart from './subComponents/bodyPart';
import KeycontactLists from './subComponents/KeycontactList';
import NormalPage from './Dataset/NormalPage';
import KeycontactPage from './Dataset/KeycontactPage';
import InsurancePage from './Dataset/InsurancePage';
import BankInvestment from './Dataset/BankInvestment';

const options = { month: 'long', day: '2-digit', year: 'numeric' };
Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: 'https://fonts.gstatic.com/s/roboto/v30/KFOmCnqEu92Fr1Me5WZLCzYlKw.ttf',
    },
    {
      src: 'https://fonts.gstatic.com/s/roboto/v30/KFOkCnqEu92Fr1Mu52xPKTM1K9nz.ttf',
      fontStyle: 'italic',
    },
    {
      src: 'https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmWUlvAx05IsDqlA.ttf',
      fontWeight: 500,
    },
    {
      src: 'https://fonts.gstatic.com/s/roboto/v30/KFOlCnqEu92Fr1MmSU5vAx05IsDqlA.ttf',
      fontWeight: 300,
    },
  ],
});
const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
    fontFamily: 'Roboto',
    // border: '1px solid black',
    // border: '1px solid black',
    // margin: 12,
  },
  container: {
    borderLeft: '1 solid #000',
    borderRight: '1 solid #000',
    borderTop: 'none',
    borderBottom: 'none',
    padding: 14,
    minHeight: '88vh',
  },
  containerpersonal: {
    border: '1 solid #000',
    padding: 14,
    // borderBottom: 'none',
    minHeight: '88vh',
  },
  title: {
    fontSize: 10,
    textAlign: 'center',
    fontWeight: 500,
  },
  titlesub: {
    fontSize: 10,
    fontWeight: 500,
    textAlign: 'center',
    marginTop: 6,
  },
  author: {
    fontSize: 12,
    textAlign: 'center',
    marginBottom: 10,
    marginTop: 20,
  },
  subtitle: {
    fontSize: 12,
    margin: 12,
  },
  questiontext: {
    margin: 12,
    marginBottom: 10,
    fontSize: 12,
    textAlign: 'justify',
    fontStyle: 'italic',
  },
  questionsubtext: {
    marginLeft: 12,
    marginBottom: 5,
    fontSize: 10,
    textAlign: 'justify',
    fontStyle: 'italic',
  },
  questionTrustsubtext: {
    marginLeft: 12,
    marginBottom: 10,
    fontSize: 12,
    textAlign: 'justify',
  },
  text: {
    margin: 12,
    fontSize: 12,
    textAlign: 'justify',
  },
  textSub: {
    marginLeft: 12,
    fontSize: 12,
    marginBottom: 10,
    textAlign: 'justify',
  },
  textListSub: {
    marginLeft: 12,
    fontSize: 12,
    textAlign: 'justify',
    color: 'red',
  },
  anstext: {
    marginLeft: 12,
    fontSize: 12,
    textAlign: 'justify',
    marginBottom: 5,
  },
  typeAnstext: {
    marginLeft: 12,
    fontSize: 12,
    textAlign: 'justify',
    marginBottom: 10,
    fontWeight: 500,
  },
  textHead: {
    margin: 12,
    marginBottom: 5,
    fontSize: 12,
    fontWeight: 500,
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: 'center',
    color: 'grey',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
  cover: {
    paddingTop: 50,
    paddingBottom: 25,
    paddingHorizontal: 35,
  },
  pageFooter: {
    position: 'absolute',
    bottom: 30,
    left: 0,
    right: 0,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  horizontalLine: {
    borderBottomWidth: 1,
    // flex: 1,
    borderBottomColor: '#A9ACAB',
    marginLeft: 10,
    marginRight: 10,
    marginBottom: 10,
  },
  horizontalBottomLineT: {
    borderBottomWidth: 1,
    borderBottomColor: 'black',
  },
  horizontalBottomLineTT: {
    borderBottomWidth: 1,
    borderBottomColor: 'black',
    marginTop: 34,
    marginBottom: 40,
  },
  horizontalLineT: {
    borderBottomWidth: 1,
    // flex: 1,
    borderBottomColor: '#A9ACAB',
    marginLeft: 10,
    marginRight: 10,
  },
  wrap: {
    marginTop: 150,
  },
  questionwrap: {
    marginBottom: 16,
  },
  page: {
    paddingVertical: 10, // Adjust padding to leave space for borders
  },
  topBorder: {
    borderTop: '1 solid #000',
  },
  bottomBorder: {
    borderBottom: '1 solid #000',
  },
});
export default function MainData({
  tableOfPersonalinfo,
  isCheck,
  binderData,
  printKeyContact,
  pascalText,
  user,
  today,
  tableOfContents,
  tableOfSubContents,
  userInfo,
}) {
  const typeArr = [
    'homeList',
    'insurance',
    'Agent',
    'healthcareProxyAgent',
    'trusty',
    'general',
  ];
  console.log(binderData);
  return (
    <Document>
      <Page style={styles.body}>
        <CoverPage
          pascalText={pascalText}
          user={user}
          today={today}
          options={options}
        />
      </Page>
      <Page style={styles.body}>
        <Text style={styles?.horizontalBottomLineT} fixed />
        <TableContent
          tableOfSubContents={tableOfSubContents}
          binderData={binderData}
          printKeyContact={printKeyContact}
          Styles={styles}
          tmpTableOfContents={tableOfContents}
        />
        <Text style={styles?.horizontalBottomLineT} fixed />
      </Page>
      <Page style={styles.body}>
        <View style={styles.containerpersonal}>
          <PersonalData userInfo={userInfo} />
        </View>
        <Footer />
      </Page>
      {binderData?.length > 0 && (
        <>
          {binderData?.map((item, mainIndex) => (
            <>
              {item?.topics?.map((subItem, subItemIndex) => (
                <Page style={styles.body}>
                  <Text style={styles?.horizontalBottomLineT} fixed />
                  <View style={styles.container}>
                    <Text style={styles.header} fixed />
                    <>
                      {subItem?.permissionType === 'normal' ? (
                        <NormalPage
                          subItemIndex={subItemIndex}
                          item={item}
                          pascalText={pascalText}
                          subItem={subItem}
                        />
                      ) : (
                        <>
                          {subItem?.permissionType === 'keyContact'
                            || subItem?.permissionType === 'organization' ? (
                              <>
                                {['Investment accounts', 'Bank accounts']?.includes(subItem?.title) ? (
                                  <BankInvestment
                                    subItemIndex={subItemIndex}
                                    item={item}
                                    pascalText={pascalText}
                                    subItem={subItem}
                                  />

                                ) : (
                                  <KeycontactPage
                                    subItemIndex={subItemIndex}
                                    item={item}
                                    pascalText={pascalText}
                                    subItem={subItem}
                                  />
                                )}
                              </>
                            ) : (
                              <>
                                {subItem?.permissionType === 'insurance'
                                || subItem?.permissionType === 'forTheHome' ? (
                                  <>
                                    <InsurancePage
                                      subItemIndex={subItemIndex}
                                      item={item}
                                      pascalText={pascalText}
                                      subItem={subItem}
                                    />
                                  </>
                                  ) : null}
                              </>
                            )}
                        </>
                      )}
                    </>
                  </View>
                  <Text style={styles?.horizontalBottomLineT} fixed />
                  <Footer />
                </Page>
              ))}
            </>
          ))}
        </>
      )}
      <Page style={styles.body}>
        <Text style={styles?.horizontalBottomLineT} fixed />
        <View style={styles.container}>
          <Text style={styles.header} fixed />
          <KeycontactLists printKeyContact={printKeyContact} />
        </View>
        <Text style={styles?.horizontalBottomLineT} fixed />
        <Footer />
      </Page>
    </Document>
  );
}
