/* eslint-disable comma-dangle */
import client from '../config/authClient';
import endpoints from '../endpoints';

const getparams = (payload) => {
  if (payload?.userId && payload?.userId !== '') { return `?userId=${payload?.userId}`; }
  return '';
};
const Service = {
  customTopic: (params) => client.post(endpoints.dashboard.addCustomCategory, params),
  deletecustomTopic: (id, params) => client.delete(`${endpoints.dashboard.removeCustomCategory}/${id}`, { params }),
  updatecustomTopic: (body) => client.put(`${endpoints.dashboard.editCustomTopic}`, body),
  getSubmenu: (params) => client.get(endpoints.dashboard.getSubmenuList, { params }),
  getCategory: (params) => client.get(`${endpoints.dashboard.getCategory + params.categoryId}`, {
    params
  }),
  categoryInfo: (params) => client.get(`${endpoints.dashboard.categoryInfo}${getparams(params)}`),
  categoryStatus: (params) => client.post(`${endpoints.dashboard.categoryStatus}${getparams(params)}`, { topics: [params?.topics] }),
  addCategory: (params) => client.post(endpoints.dashboard.addCategory, params),
  removeCategory: (params) => client.post(endpoints.dashboard.removeCategory, params),
  getTopicWizard: (params) => client.get(`${endpoints.dashboard.getTopicWizard + params.topicId}`, {
    params
  }),
  wizardAnswer: (params) => client.post(endpoints.dashboard.wizardAnswer, params),
  getFileDownloadLink: (id) => client.get(`${endpoints.dashboard.upload}/${id}`),
  deleteFile: (params) => client.delete(`${endpoints.dashboard.upload}/${params?.id}`, { params }),
  getTrust: () => client.get(endpoints.organization.getTrust),
  TrustAdd: (params) => client.post(endpoints.organization.getTrust, params),
  TrustDelete: (id) => client.delete(`${endpoints.organization.getTrust}/${id}`),
  updateTrust: (id, body) => client.put(`${endpoints.organization.getTrust}/${id}`, body),
  quickwill: () => client.get(`${endpoints.dashboard.quickwill}`),
  binderPrint: () => client.get(`${endpoints.binder.print}`),
  auditPrint: () => client.get(`${endpoints.binder.audit}`),
  search: (params) => client.post(endpoints.search.search, params),
  deleteAccount: () => client.post(`${endpoints.user.delete}`),
};

export default Service;
